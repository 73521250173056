import { createSlice } from '@reduxjs/toolkit';
import { getQuoteAsync, getQuoteHashAsync } from './Actions';

export interface QuoteState {
  status: 'idle' | 'loading' | 'failed';
  errorMessage: string | undefined;
  refreshToken: boolean;
  quoteHash: any;
  //plans: any,
  quote: any;
  selectedPlan: any;
  selectedCoverages: any;
}

const initialState: QuoteState = {
  status: 'idle',
  errorMessage: '',
  refreshToken: false,
  quoteHash: null,
  //plans: null,
  quote: null,
  selectedPlan: null,
  selectedCoverages: null,
};

export const quoteSlice = createSlice({
  name: 'Quote',
  initialState,
  reducers: {
    saveSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    saveSelectedCoverages: (state, action) => {
      state.selectedCoverages = action.payload;
    },
    saveHash: (state, action) => {
      state.quoteHash = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuoteHashAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getQuoteHashAsync.rejected, (state, action: any) => {
        state.status = 'failed';
        state.quoteHash = '';
        state.errorMessage = action.payload && action.payload.errorMessage;
        state.refreshToken = action.payload && action.payload.refreshToken;
      })
      .addCase(getQuoteHashAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.quoteHash =
          action.payload && action.payload.Location.split('ventas/')[1];
        state.errorMessage = '';
      })
      .addCase(getQuoteAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getQuoteAsync.rejected, (state, action: any) => {
        state.status = 'failed';
        state.quote = null;
        state.errorMessage = action.payload && action.payload.errorMessage;
        state.refreshToken = action.payload && action.payload.refreshToken;
      })
      .addCase(getQuoteAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.quote = action.payload;
        state.errorMessage = '';
      });
  },
});

export const { saveHash, saveSelectedPlan, saveSelectedCoverages } = quoteSlice.actions;

export default quoteSlice.reducer;
