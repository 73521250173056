import API from "./api";

export class PhoneValidatorService {

    public validatePhone = async (prefix: string, phone:string) => {
        const res = API.get(`/negocio/validaciones/telefono/prefijo/${prefix}/numero/${phone}`);
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err;
        })
    };

}