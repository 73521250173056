import { createSlice } from '@reduxjs/toolkit';

export interface PaymentState {
  paymentFormData: any,
}

const initialState: PaymentState = {
  paymentFormData: null,
};


export const paymentSlice = createSlice({
  name: 'Payment',
  initialState,
  reducers: {
    savePaymentFormData: (state, action) => {
      state.paymentFormData = action.payload;
    },
  },
  extraReducers: (builder) => {
  },
});

export const { savePaymentFormData } = paymentSlice.actions;

export default paymentSlice.reducer;
