import { createSlice } from "@reduxjs/toolkit";
import { getBeneficiaryTypesAsync } from "./Actions";

export interface BeneficiaryTypesValues {
  value: string;
  label: string;
}

export interface BeneficiaryTypesState {
  status: "idle" | "loading" | "failed";
  beneficiaryTypes: Array<BeneficiaryTypesValues>;
  errorMessage: string | undefined;
}

const initialState: BeneficiaryTypesState = {
  status: "idle",
  beneficiaryTypes: [],
  errorMessage: "",
};

export const beneficiaryTypesSlice = createSlice({
  name: "BeneficiaryTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBeneficiaryTypesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBeneficiaryTypesAsync.rejected, (state, action: any) => {
        state.status = "failed";
        state.beneficiaryTypes = [];
        state.errorMessage = action.payload && action.payload.errorMessage;
      })
      .addCase(getBeneficiaryTypesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.beneficiaryTypes = action.payload;
        state.errorMessage = "";
      });
  },
});

export default beneficiaryTypesSlice.reducer;
