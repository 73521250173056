import { RootState } from "../store";

export const selectLocalities = (state: RootState) => state.localities.list;

export const selectLocalitiesCombo = (state: RootState) => {
    let list = state.localities.list.map((item) =>{
        return {
            name: item.label,
            value: item.value
        }
    });
    list.unshift({ name: "", value: "" })
    return list; 
};

export const selectErrorMessageLocalities = (state: RootState) => state.localities.errorMessage;
