import { RootState } from "../store";
import { TakerActivitiesValues } from "./Slice";

const getTakerActivitiesCombo = (values: Array<TakerActivitiesValues>) => {
    let options:Array<{ value:string, name: string, disabled?: boolean }> = [{ name: "", value: ""}];
    let takerActivities = [...values];
    
    takerActivities.forEach((activitie) => {
        let option = { name: activitie.label, value: activitie.value };
        options.push(option);
    });
    return options;
}

export const selectTakerActivities = (state: RootState) => state.takerActivities.list;
export const selectTakerActivitiesCombo = (state: RootState) => getTakerActivitiesCombo(state.takerActivities.list);
export const selectErrorMessageTakerActivities = (state: RootState) => state.takerActivities.errorMessage;