import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import InsuredForm from './insured-form';
import './Insureds.css'
import * as lang from "./lang.json";
import { validateForm } from './insuredFormValidations';
import { useAppSelector } from '../../../Store/hooks';
import { selectEnvVariables } from '../../../Store/Variables/selectors';

/**
 * Interface used as props.
 * @name InsuredsProps
 */
 export interface InsuredsProps {
  count?: Number,
  onChange: (data: Array<InsuredValueProps>) => void,
  values: Array<InsuredValueProps>
};

/**
 * Interface used as props.
 * @name InsuredProps
 */
 export interface InsuredValueProps {
  index: number,
  name: string,
  lastname: string,
  documentNumber: string,
  birthDate: string,
  errorsMessages: Array<{field: string, message: string}>
};

/**
 * Main component.
 * @name Insureds
 * @param {InsuredsProps}
 * @returns {<Insureds />}
 */


const Insureds = ({ count = 1, onChange = (data: Array<InsuredValueProps>) => {}, values }: InsuredsProps, ref: any) => {
  const [formData, setFormData] = useState<Array<InsuredValueProps>>(values);
  const handleChange = (data: InsuredValueProps, index: number) => {
    const newFormData = [...formData];
    newFormData[index] = data;
    setFormData(newFormData);
  };
  const variables = useAppSelector(selectEnvVariables) as any;

  useEffect(() => {
    onChange([...formData]);
  }, [formData])
    
  useImperativeHandle(ref, () => ({
    validateForm: () => {
      let currentForm: Array<InsuredValueProps> = validateForm(formData);
      setFormData(currentForm);
      const hasError = currentForm.filter(a => a.errorsMessages.length > 0).length > 0;

      return !hasError;
    }
  }), [formData]);

  return (
    <div className="insureds-container">
      <div className="insureds-title insureds-title-mobile" style={{ color: variables.REACT_APP_ACCENT_COLOR }}>
          <span>{lang.title}</span>
      </div>
      <div>
          {[...Array(count)].map((e,i) => (<InsuredForm formValues={formData[i]} index={i} onChange={handleChange} key={'InsuredForm'+ i}/>))}
      </div>
    </div>
  );
};

export default forwardRef(Insureds);
