import {createSlice} from "@reduxjs/toolkit";
import {getEnvVariablesAsync} from "./actions";

export interface EnvVariablesState {
  status: "idle" | "loading" | "failed";
  variables: {};
  errorMessage: string | undefined;
}

const initialState: EnvVariablesState = {
  status: "idle",
  variables: {},
  errorMessage: "",
};

export const envVariablesSlice = createSlice({
  name: "Variables",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEnvVariablesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getEnvVariablesAsync.rejected, (state, action: any) => {
        state.status = "failed";
        state.variables = {};
        state.errorMessage = action.payload.errorMessage;
      })
      .addCase(getEnvVariablesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.variables = action.payload;
        state.errorMessage = "";
      });
  },
});

export default envVariablesSlice.reducer;
