import { createSlice } from '@reduxjs/toolkit';
// import {getTokenAsync} from './Actions';

export interface ModalFormErrorsState {
  status: 'idle' | 'loading' | 'failed';
  messages: Array<string>;
}

const initialState: ModalFormErrorsState = {
  status: 'idle',
  messages: []
};


export const modalFormErrorsSlice = createSlice({
  name: 'ModalFormErrors',
  initialState,
  reducers: {
    showModalFormErrors: (state, action) => {
      state.messages = action.payload;
    },
    hideModalFormErrors: (state) => {
      state.messages = [];
    }
  },
  extraReducers: (builder) => {
    /* None */
  }
});

export default modalFormErrorsSlice.reducer;
