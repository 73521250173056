import { RootState } from "../store";
import { GendersValues } from "./Slice";

const getGendersCombo = (values: Array<GendersValues>) => {
    let options:Array<{ value:string, label: string, disabled?: boolean }> = [];
    let Genders = [...values];
    
    Genders.forEach((gender) => {
        let option = { label: gender.label, value: gender.value };
        options.push(option);
    });
    return options;
}

export const selectGenders = (state: RootState) => state.genders.list;
export const selectGendersCombo = (state: RootState) => getGendersCombo(state.genders.list);
export const selectErrorMessageGenders = (state: RootState) => state.genders.errorMessage;