import API from "./api";

export class NationalitiesService {

    public getNationalities = async () => {
        const res = API.get('/negocio/nacionalidades/opciones');
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err;
        })
    };

}