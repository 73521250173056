import { Validations } from "@experta/commons-v2.layout";
import { InsuredValueProps } from "./insureds";
import * as lang from "./lang.json";

export const validateForm = (currentForm: Array<InsuredValueProps> ) => {
  let isValid: boolean = true;

  currentForm.forEach((item: InsuredValueProps, index: number) => {
    item.errorsMessages= [];

    if(!(Validations.required(item.name))) {
      isValid = false;
      item.errorsMessages.push({field: "name", message: lang.errors.required});

    } else if(!(Validations.fullName(item.name))) {
      isValid = false;
      item.errorsMessages.push({field: "name", message: lang.errors.invalid});
    }

    if(!(Validations.required(item.lastname))) {
      isValid = false;
      item.errorsMessages.push({field: "lastname", message: lang.errors.required});
    } else if(!(Validations.fullName(item.lastname))) {
      isValid = false;
      item.errorsMessages.push({field: "lastname", message: lang.errors.invalid});
    }

    if(!(Validations.number(item.documentNumber) && Validations.minLength(item.documentNumber, 7) && Validations.maxLength(item.documentNumber, 8) && currentForm.filter((el: InsuredValueProps) => item.documentNumber == el.documentNumber).length === 1)){
      isValid = false;
      item.errorsMessages.push({field: "documentNumber", message: lang.errors.required});
    } else if(!(Validations.number(item.documentNumber) && Validations.minLength(item.documentNumber, 7) && Validations.maxLength(item.documentNumber, 8) && currentForm.filter((el: InsuredValueProps) => item.documentNumber == el.documentNumber).length === 1)){
      isValid = false;
      item.errorsMessages.push({field: "documentNumber", message: lang.errors.invalid});
    }

    const youngerDate =  (new Date(item.birthDate));
    youngerDate.setFullYear((new Date(item.birthDate)).getFullYear() + 18);
    const isYounger =  youngerDate >= new Date();

    const olderDate =  (new Date(item.birthDate));
    olderDate.setFullYear((new Date(item.birthDate)).getFullYear() + 65);
    const isOlder =  olderDate < new Date();

    if(!(Validations.required(item.birthDate))) {
      isValid = false;
      item.errorsMessages.push({field: "birthDate", message: lang.errors.required});
    } else if(isYounger || isOlder) {
      isValid = false;
      item.errorsMessages.push({field: "birthDate", message: lang.errors.legalAge});
    }

  });

  return currentForm;

}