import "./Beneficiary.css";
import * as lang from "./lang.json";
import {InputRadio} from "@experta/commons-v2.input-radio";
import {Input} from "@experta/commons-v2.input";
import { forwardRef, useEffect, useImperativeHandle, useReducer } from "react";
import NonRepetitionClauseForm from "./NonRepetitionClauseForm";
import BeneficiaryInsuredForm from "./BeneficiaryInsuredForm";
import { BeneficiaryForm, BeneficiaryProps, CompanyData, getRadioBeneficiaryTypeValues, validateForm } from "./utils";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { selectBeneficiaryTypes } from "../../../Store/BeneficiaryTypes/Selectors";
import { hideSpinner, showSpinner } from "../../../Store/Spinner/Actions";
import { getBeneficiaryTypesAsync } from "../../../Store/BeneficiaryTypes/Actions";
import { selectBeneficiaryFormData } from "../../../Store/Forms/Selectors";
import { selectEnvVariables } from "../../../Store/Variables/selectors";



/**
 * Main component.
 * @name Beneficiary
 * @param {BeneficiaryProps}
 * @returns {<Beneficiary />}
 */
const Beneficiary = ({
  insureds,
  onChangeFormData = (formData: any) => { },
}: BeneficiaryProps, ref: any) => {

  const dispatch = useAppDispatch();
  const beneficiaryTypes = useAppSelector(selectBeneficiaryTypes);
  const beneficiariesDataStoreForm = useAppSelector(selectBeneficiaryFormData);
  const variables = useAppSelector(selectEnvVariables) as any;

  const formReducer = (state: BeneficiaryForm, event: any) => {
    const newState = {
      ...state,
      [event.name]: event.value,
    };
    onChangeFormData(newState);
    return newState;
  };
  const [formData, setFormData] = useReducer(formReducer, JSON.parse(JSON.stringify(beneficiariesDataStoreForm)));

  const handleChange = (e: any) => {
    if (e.target.value === "JURIDICO") {
      setFormData({
        name: "companyData",
        value: {
          cuit: "",
          businessName: "",
          errorsMessages: []
        },
      });
      setFormData({
        name: "beneficiaryInsures",
        value: [],
      });
    }

    if (e.target.value === "TOMADOR") {
      setFormData({
        name: "beneficiaryInsures",
        value: [],
      });

      setFormData({
        name: "companyData",
        value: { businessName: '', cuit: '', errorsMessages: [] },
      });
    }

    if (e.target.value === "FISICO") {
      setFormData({
        name: "companyData",
        value: { businessName: '', cuit: '', errorsMessages: [] },
      });
      setFormData({
        name: "beneficiaryInsures",
        value: [],
      });
    }

    setFormData({
      name: e.target.name,
      value: e.target.value,
    });
  };

  const handleChangeCompany = (e: any) => {
    let companyData: CompanyData = {...formData.companyData, [e.target.name]: e.target.value  };

    setFormData({
      name: "companyData",
      value: companyData,
    });
  };

  const handleChangeBeneficiaryInsures = (data: any, index: number) => {
    let beneficiaryInsures = [...formData.beneficiaryInsures];
    beneficiaryInsures[index] = data;
    setFormData({
      name: "beneficiaryInsures",
      value: beneficiaryInsures,
    });
  };

  const handleChangeClause = (data: any) => {
    setFormData({
      name: "nonRepetitionClause",
      value: data,
    });
  };

  useEffect(() => {
    if(!beneficiaryTypes.length) {
      const getBeneficiaryTypes = async () => {
        await dispatch(showSpinner());
        await dispatch(getBeneficiaryTypesAsync());
        await dispatch(hideSpinner());
      }
      getBeneficiaryTypes();
    }
  }, []);

  useImperativeHandle(ref, () => ({
    validateForm: () => {

      let currentForm: BeneficiaryForm = validateForm(formData);
      setFormData({...currentForm});
      const hasBeneficiariesErrors = currentForm.beneficiaryInsures.filter(a => a.filter(b => b.errorsMessages.length > 0).length > 0).length > 0;      
      const hasNonRepetitionClauseError = currentForm.nonRepetitionClause.filter(a => a.errorsMessages.length > 0).length > 0;      
      const hasCompanyDataError = currentForm.companyData.errorsMessages.length > 0; 
      if(hasBeneficiariesErrors || hasNonRepetitionClauseError || hasCompanyDataError) {
        return false;
      }   
      
      return true;
    }
  }), [formData]);


  const hasError = (name: string) => {
    return formData.companyData.errorsMessages.filter(el => el.field === name).length > 0;
  };

  const getError = (name: string) => {
    if(hasError(name)){
      return formData.companyData.errorsMessages.filter(el => el.field === name)[0].message;
    }
    else {
      return undefined;
    }
  };

  

  return (
    <div className="beneficiary-container">
      <div className="beneficiary-title" style={{ color: variables.REACT_APP_ACCENT_COLOR }}>
        <span>{lang.title}</span>
      </div>
      <div className="beneficiary-subtitle">
        <span>{lang.subtitle}</span>
      </div>

      <div>
        <InputRadio
          onChange={handleChange}
          values={getRadioBeneficiaryTypeValues(beneficiaryTypes, formData)}
        />
        {formData.beneficiaryType === "JURIDICO" && (
          <div className="row mt-4">
            <div className="col-md-6 col-12">
              <Input
                name="cuit"
                label={lang.cuit}
                onChange={handleChangeCompany}
                value={formData.companyData.cuit}
                type="text"
                error={hasError("cuit")}
                errorMessage={getError("cuit")}
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                name="businessName"
                label={lang.businessName}
                onChange={handleChangeCompany}
                value={formData.companyData.businessName}
                type="text"
                error={hasError("businessName")}
                errorMessage={getError("businessName")}
              />
            </div>
          </div>
        )}

        {formData.beneficiaryType === "FISICO" &&
          insureds.map((insured) => {
            return(
              <BeneficiaryInsuredForm
                onChange={handleChangeBeneficiaryInsures}
                key={"BeneficiaryInsuredForm" + insured.index}
                indexRoot={insured.index}
                name={insured.name}
                lastname={insured.lastname}
                dni={insured.documentNumber}
                errorsMessages={insured.errorsMessages}
              />
            )
          })}
          

        <NonRepetitionClauseForm
          onChange={(data) => handleChangeClause(data)}
        />
      </div>
    </div>
  );
};

export default forwardRef(Beneficiary);
