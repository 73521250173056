import { createSlice } from "@reduxjs/toolkit";
import { getPlansTypeAsync } from "./Actions";

export interface PlansTypeValues {
  value: string; 
  label: string; 
  description: string
}

export interface PlansTypeStates {
  status: "idle" | "loading" | "failed";
  plansType: Array<PlansTypeValues>;
  errorMessage: string | undefined;
}

const initialState: PlansTypeStates = {
  status: "idle",
  plansType: [],
  errorMessage: "",
};

export const plansTypeSlice = createSlice({
  name: "PlansType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlansTypeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPlansTypeAsync.rejected, (state, action: any) => {
        state.status = "failed";
        state.plansType = [];
        state.errorMessage = action.payload && action.payload.errorMessage;
      })
      .addCase(getPlansTypeAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.plansType = action.payload;
        state.errorMessage = "";
      });
  },
});

export default plansTypeSlice.reducer;
