import { useEffect, useState } from 'react';
import { selectErrorMessageActivities } from '../../Store/Activities/Selectors';
import { selectErrorMessageEmmitQuote } from '../../Store/EmmitQuote/Selectors';
import { useAppSelector } from '../../Store/hooks';
import { selectErrorMessageInsured } from '../../Store/Insured/Selectors';
import { selectErrorMessageLocalities } from '../../Store/Localities/Selectors';
import { selectErrorMessagePlansType } from '../../Store/PlansType/Selectors';
import { selectErrorMessagePlans } from '../../Store/Quote/Selectors';
import './ErrorModal.css'
import * as lang from "./lang.json"

/**
 * Main component.
 * @name ErrorModal
 * @returns {<ErrorModal />}
 */


const ErrorModal = () => {
  const [showModal, setShowModal] = useState(false);
  const errorMessageActivities = useAppSelector(selectErrorMessageActivities);
  const errorMessageInsured = useAppSelector(selectErrorMessageInsured);
  const errorMessagePlansType = useAppSelector(selectErrorMessagePlansType);
  const errorMessageQuote = useAppSelector(selectErrorMessagePlans);
  const errorMessageLocalities = useAppSelector(selectErrorMessageLocalities);

  useEffect(() => {
    if (errorMessagePlansType !== '' || errorMessageInsured !== '' || errorMessageActivities !== '' || errorMessageQuote !== '' ||  errorMessageLocalities !== ''){
      setShowModal(true);
    }
  },[errorMessagePlansType, errorMessageInsured, errorMessageActivities, errorMessageLocalities, errorMessageQuote]);
  
  return (
      <>
        {showModal && (
          <div className="service-errors-modal-container modal-bg">
            <div className="modal-content">
              <h1>{ lang.title }</h1>
              <h3>{ errorMessageActivities }{ errorMessagePlansType }{ errorMessageInsured }{ errorMessageQuote }{ errorMessageLocalities }</h3>
              <br />
              <button onClick={() => setShowModal(false)}>{ lang.button_text }</button>
            </div>
          </div>
        )}
      </>
  );
};

export default ErrorModal;