import API from "./api";

export class CardsBrandsService {

    public getCardsBrands = async () => {
        const res = API.get('/negocio/marcas-tarjetas-credito/opciones');
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err
        })
    };

}