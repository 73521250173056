import API from "./api";
import * as lang from "./../Components/FormQuote/lang.json"

export class ValidityTypesService {
    
    public getValidityTypes = async () => {
        const res = API.get('/negocio/tipos-vigencia/opciones');
        return res.then(async (resp) => {
            // resp.data = resp.data.map ( (item: any) => { return { value: item.value, name: item.label  } });
            resp.data.unshift({
                name: lang.form.EmptyComboName,
                value: "",
            });
            return resp;
        })
        .catch(err => {
            return err;
        })
    };

}