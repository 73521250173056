// React
import { useRef, useState, useEffect, useLayoutEffect } from 'react';

// Libraries
import { useHistory } from 'react-router-dom';

// Commons
import { Button } from '@experta/commons-v2.button';
import { CheckboxWithReason } from '@experta/commons-v2.checkbox-with-reason';
import { InfoHeader } from '@experta/commons-v2.info-header';
import { PersonalData } from '@experta/commons-v2.personal-data';
import { PersonalDataFormProps } from '@experta/commons-v2.personal-data/dist/utils';
import { StepsPayment } from '@experta/commons-v2.steps-payment';

// Parts
import Beneficiary from '../../Parts/Forms/Beneficiary/Beneficiary';
import Insureds from '../../Parts/Forms/Insureds/insureds';

// Store
import { getCardsBrandsAsync } from '../../Store/CardsBrands/Actions';
import { getGendersAsync } from '../../Store/Genders/Actions';
import { getLocalitiesAsync } from '../../Store/Localities/Actions';
import { getNationalitiesAsync } from '../../Store/Nationalities/Actions';
import { getPaymentMethodsAsync } from '../../Store/PaymentMethods/Actions';
import { getPersonTypesAsync } from '../../Store/PersonTypes/Actions';
import { getTakerActivitiesAsync } from '../../Store/TakerActivities/Actions';
import { getTaxConditionsAsync } from '../../Store/TaxConditions/Actions';
import { hideSpinner, showSpinner } from '../../Store/Spinner/Actions';
import {
  saveBeneficiaryFormData,
  saveInsuredsFormData,
  saveObligatedSubjectFormData,
  savePersonalFormData,
  savePoliticallyExposedFormData,
} from '../../Store/Forms/Slice';
import { saveDataForm } from '../../Store/Data/Slice';
import { selectActivitiesCombo } from '../../Store/Activities/Selectors';
import {
  selectBeneficiaryFormData,
  selectInitialFormData,
  selectInsuredsFormData,
  selectObligatedSubjectFormData,
  selectPersonalFormData,
  selectPoliticallyExposedFormData,
} from '../../Store/Forms/Selectors';
import { selectEnvVariables } from '../../Store/Variables/selectors';
import { selectGendersCombo } from '../../Store/Genders/Selectors';
import { selectLocalitiesCombo } from '../../Store/Localities/Selectors';
import { selectNationalitiesCombo } from '../../Store/Nationalities/Selectors';
import { selectPersonTypes } from '../../Store/PersonTypes/Selectors';
import {
  selectQuote,
  selectQuoteHash,
  selectSelectedPlan,
} from '../../Store/Quote/Selectors';
import { selectTakerActivitiesCombo } from '../../Store/TakerActivities/Selectors';
import { selectTaxConditions } from '../../Store/TaxConditions/Selectors';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';

// Assets
import Icon1 from '../../Assets/Icons/ICONO_COTIZACION.svg';
import Icon2 from '../../Assets/Icons/ICONO_PLANES.svg';
import Icon3 from '../../Assets/Icons/ICONO_DATOS_white.svg';
import Icon4 from '../../Assets/Icons/ICONO_PAGO.svg';

// Languages
import * as lang from './lang.json';

// Utils
import { getTextFormatPrice } from '../Plans/utils';
import { personalDataFormAsyncValidation } from './personalDataFormAsyncValidations';
import { useDataUtils } from './utils';

// Styles
import './Data.css';

// Debounce
import debounce from 'lodash.debounce';

/**
 * Interface used as props.
 * @name DataPageProps
 */
export interface DataPageProps {}

interface ClientData {
  beneficiaryFormData: any;
  insuredsFormData: Array<{
    birthDate: string;
    documentNumber: string;
    index: number;
    lastname: string;
    name: string;
  }>;
  personalFormData: any;
}

/**
 * Main component.
 * @name DataPage
 * @param {DataPageProps}
 * @returns {<DataPage />}
 */

const DataPage = ({}: DataPageProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { obligatedSubjectParams, tycParams } = useDataUtils();
  const variables = useAppSelector(selectEnvVariables) as any;

  //Selectors
  const selectedPlan = useAppSelector(selectSelectedPlan);
  const localitiesComboSelector = useAppSelector(selectLocalitiesCombo);
  const nationalities = useAppSelector(selectNationalitiesCombo);
  const activities = useAppSelector(selectActivitiesCombo);
  const takerActivities = useAppSelector(selectTakerActivitiesCombo);
  const initialFormData = useAppSelector(selectInitialFormData);
  const quote = useAppSelector(selectQuote);
  const quoteHash = useAppSelector(selectQuoteHash);
  const storePersonTypes = useAppSelector(selectPersonTypes);
  const storeTaxConditions = useAppSelector(selectTaxConditions);
  const storeGenders = useAppSelector(selectGendersCombo);
  const personalDataStoreForm = useAppSelector(selectPersonalFormData);

  const politicallyExposedDataStoreForm = useAppSelector(
    selectPoliticallyExposedFormData
  );
  const beneficiaryDataStoreForm = useAppSelector(selectBeneficiaryFormData);
  const insuredsDataStoreForm = useAppSelector(selectInsuredsFormData);
  const obligatedSubjectDataStoreForm = useAppSelector(
    selectObligatedSubjectFormData
  );

  //useEffects
  useEffect(() => {
    setLocalities([...localitiesComboSelector]);
  }, [localitiesComboSelector]);

  useLayoutEffect(() => {
    (async () => {
      dispatch(showSpinner());
      await dispatch(getNationalitiesAsync());
      await dispatch(getTakerActivitiesAsync());
      await dispatch(getGendersAsync());
      await dispatch(getPersonTypesAsync());
      await dispatch(getTaxConditionsAsync('FISICA'));
      dispatch(hideSpinner());
    })();
  }, []);

  //useRefs
  const CheckboxWithReasonRef = useRef();
  const personalDataRef = useRef();
  const InsuredsDataRef = useRef();
  const beneficiaryDataRef = useRef();

  //Variables
  const currentStep = 2;
  const stepsStyle = {
    color: 'white',
    bgColor: 'rgba(23, 160, 213, 0.7)',
    bgColorActive: 'rgba(23, 160, 213, 1)',
  };
  const stepsPayment = [
    { title: lang.step1Title, icon: Icon1 },
    { title: lang.step2Title, icon: Icon2 },
    { title: lang.step3Title, icon: Icon3 },
    { title: lang.step4Title, icon: Icon4 },
  ];
  const valuesInfoHeader = [
    {
      title: 'Actividad',
      value: activities.filter(
        (activity) => activity?.value === initialFormData?.activity
      )[0]?.name,
    },
    { title: 'Personas Aseguradas', value: initialFormData?.quantity },
    { title: 'Desde', value: initialFormData?.dateFrom },
    { title: 'Hasta', value: initialFormData?.dateTo },
    { title: 'Plan', value: selectedPlan?.nombrePlan },
    {
      title: 'Precio',
      value: getTextFormatPrice({
        validity: quote?.vigencia.tipoVigencia,
        plan: selectedPlan,
      }),
    },
  ];
  const backgroundColorInfoHeader = 'rgb(238,238,238)';

  //Methods
  const fetchLocalities = async (zipCode: string) => {
    dispatch(showSpinner());
    await dispatch(getLocalitiesAsync(zipCode));
    dispatch(hideSpinner());
  };
  const fetchTaxConditions = async (personType: string) => {
    dispatch(showSpinner());
    await dispatch(getTaxConditionsAsync(personType));
    dispatch(hideSpinner());
  };
  const handleChangePersonalFormData = debounce(
    (formData: PersonalDataFormProps) => {
      //trigger localities change
      if (
        personalFormData?.zipCode !== formData.zipCode &&
        formData.zipCode.length >= 4
      ) {
        fetchLocalities(formData.zipCode);
      }
      if (personalFormData?.tipoPersona !== formData.tipoPersona) {
        fetchTaxConditions(formData.tipoPersona);
      }
      setPersonalFormData(formData);
    },
    350
  );
  const handleSubmit = async () => {
    const clientData = {
      personalFormData,
      beneficiaryFormData,
      insuredsFormData,
      politicallyExposedFormData,
      obligatedSubjectFormData,
    };

    dispatch(showSpinner());
    const isValid = await validateStep();
    if (isValid) {
      dispatch(savePersonalFormData(clientData.personalFormData));
      dispatch(
        savePoliticallyExposedFormData(clientData.politicallyExposedFormData)
      );
      dispatch(
        saveObligatedSubjectFormData(clientData.obligatedSubjectFormData)
      );
      dispatch(saveInsuredsFormData(clientData.insuredsFormData));
      dispatch(saveBeneficiaryFormData(clientData.beneficiaryFormData));
      dispatch(saveDataForm(clientData));
      await dispatch(getPaymentMethodsAsync());
      await dispatch(getCardsBrandsAsync());
      history.push(`/pago?hashId=${quoteHash}`);
    }
    dispatch(hideSpinner());
  };
  const validateStep = async () => {
    let isValid = true;
    const personalDataFormErrorMessages = await (
      personalDataRef.current as any
    ).validateForm();
    const checkboxWithReasonErrorMessages = (
      CheckboxWithReasonRef.current as any
    ).validateData();
    const insuredsDataFormErrorMessages = (
      InsuredsDataRef.current as any
    ).validateForm();
    const beneficiaryDataFormErrorMessages = (
      beneficiaryDataRef.current as any
    ).validateForm();
    if (
      !personalDataFormErrorMessages ||
      !insuredsDataFormErrorMessages ||
      !beneficiaryDataFormErrorMessages ||
      !checkboxWithReasonErrorMessages
    ) {
      isValid = false;
    }
    return isValid;
  };

  //useStates
  const [localities, setLocalities] = useState<any>([]);

  // Use data in Store as initial state of forms.
  // JSON.parse(JSON.stringify(storeSelector)); for making a ture copy and not mutate the store
  const [personalFormData, setPersonalFormData] = useState(
    JSON.parse(JSON.stringify(personalDataStoreForm))
  );
  const [politicallyExposedFormData, setPoliticallyExposedFormData] = useState(
    JSON.parse(JSON.stringify(politicallyExposedDataStoreForm))
  );
  const [insuredsFormData, setInsuredsFormData] = useState(
    JSON.parse(JSON.stringify(insuredsDataStoreForm))
  );
  const [beneficiaryFormData, setBeneficiaryFormData] = useState(
    JSON.parse(JSON.stringify(beneficiaryDataStoreForm))
  );
  const [obligatedSubjectFormData, setObligatedSubjectFormData] = useState(
    JSON.parse(JSON.stringify(obligatedSubjectDataStoreForm))
  );

  useEffect(() => {
    setPersonalFormData(personalDataStoreForm);
  }, [personalDataStoreForm]);

  useEffect(() => {
    setInsuredsFormData(insuredsDataStoreForm);
  }, [insuredsDataStoreForm]);

  const checkboxWithReasonDescription =
    politicallyExposedFormData.politicallyExposed
      ? tycParams.description
      : tycParams.altDescription;

  return (
    <>
      <div
        className='info-header-wrapper'
        style={{ backgroundColor: backgroundColorInfoHeader }}
      >
        <InfoHeader
          title={lang.infoHeaderTitle}
          insuredAssets={valuesInfoHeader}
          backgroundColor={backgroundColorInfoHeader}
        />
      </div>
      <StepsPayment
        currentStep={currentStep}
        style={stepsStyle}
        steps={stepsPayment}
      />
      <div className='data-container px-3 py-5 p-lg-5 mb-3'>
        <div
          className='personal-data-title'
          style={{ color: variables.REACT_APP_ACCENT_COLOR }}
        >
          <span>{lang.personalDataTitleContracting}</span>
        </div>
        {localities?.length &&
          storeTaxConditions?.length &&
          storePersonTypes?.length &&
          storeGenders?.length &&
          personalFormData?.name && (
            <PersonalData
              ref={personalDataRef}
              formData={personalFormData}
              handleChange={handleChangePersonalFormData}
              localities={localities}
              nationalities={nationalities}
              takerActivities={takerActivities}
              taxConditions={storeTaxConditions}
              personTypes={storePersonTypes}
              personSexs={storeGenders}
              validateMethod={personalDataFormAsyncValidation}
            />
          )}
        <CheckboxWithReason
          title={tycParams.title}
          description={checkboxWithReasonDescription}
          requiredCheckBox={false}
          showReasonTextInput
          returnInfo={(data: any) =>
            setPoliticallyExposedFormData({
              politicallyExposed: data.checkboxValue,
              reason: data.reason,
            })
          }
          ref={CheckboxWithReasonRef}
        />
        <CheckboxWithReason
          title={obligatedSubjectParams.obligatedSubjectTitle}
          description={obligatedSubjectParams.obligatedSubjectDescription}
          requiredCheckBox={false}
          showReasonTextInput={false}
          returnInfo={(data: any) =>
            setObligatedSubjectFormData({
              obligatedSubject: data.checkboxValue,
            })
          }
        />
        {insuredsFormData.length && (
          <Insureds
            values={insuredsFormData}
            count={Number(initialFormData?.quantity)}
            onChange={(data: any) => setInsuredsFormData(data)}
            ref={InsuredsDataRef}
          />
        )}
        <Beneficiary
          ref={beneficiaryDataRef}
          insureds={insuredsFormData}
          onChangeFormData={(data: any) => setBeneficiaryFormData(data)}
        />
        <div className='beneficiary-container'>
          <div className='row'>
            <div className='col-md-6 d-flex justify-content-md-start justify-content-center mb-3'>
              <Button
                className='button-back button-secondary-outline-ap'
                onClick={() => history.push(`/planes?hashId=${quoteHash}`)}
              >
                {lang.back}
              </Button>
            </div>
            <div className='col-md-6 d-flex justify-content-md-end justify-content-center'>
              <Button
                onClick={handleSubmit}
                className='button-next button-primary-solid-ap'
              >
                {lang.next}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataPage;
