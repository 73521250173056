import './Beneficiary.css'
import * as lang from "./lang.json";
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {Input} from '@experta/commons-v2.input';
import {Button} from "@experta/commons-v2.button";
import { BeneficiaryInsure } from './utils';
import { selectBeneficiaryFormData } from '../../../Store/Forms/Selectors';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import TrashIcon from '../../../Assets/Icons/trash-icon.svg';
import {Select} from '@experta/commons-v2.select';
import { getBeneficiaryRelationshipTypesAsync } from '../../../Store/BeneficiaryRelationshipTypes/actions';
import { selectBeneficiaryRelationshipTypes } from '../../../Store/BeneficiaryRelationshipTypes/selectors';
/**
 * Interface used as props.
 * @name BeneficiaryInsuredFormProps
 */
export interface BeneficiaryInsuredFormProps {
    indexRoot: number,
    name: string,
    lastname: string,
    dni: string,
    beneficiaryRelationship?: string,
    errorsMessages: Array<{field: string, message: string}>,
    onChange?: (data: Array<BeneficiaryInsuredForm>, index: number) => void
};

/**
 * Interface of beneficiary insured form.
 * @name BeneficiaryInsuredForm
 */
export interface BeneficiaryInsuredForm {
    name: string;
    lastname: string;
    dni: string;
    beneficiaryRelationship: string;
    errorsMessages: Array<{field: string, message: string}>;
};

/**
 * Main component.
 * @name BeneficiaryInsuredForm
 * @param {BeneficiaryInsuredFormProps}
 * @returns {<BeneficiaryInsuredForm />}
 */
const BeneficiaryInsuredForm = ({ indexRoot, name, lastname, dni, errorsMessages = [], onChange = (data: Array<BeneficiaryInsuredForm>) => { } }: BeneficiaryInsuredFormProps) => {
    const beneficiariesDataStoreForm = useAppSelector(selectBeneficiaryFormData);
    const dispatch = useAppDispatch();
    const options = useAppSelector(selectBeneficiaryRelationshipTypes);
    const [beneficiaryRelationshipCombo, setBeneficiaryRelationshipCombo] = useState<Array<{
        name: string,
        value: string,
    }>>([]);
    
    const getBeneficiaryState = () => beneficiariesDataStoreForm.beneficiaryInsures[indexRoot]
        ? JSON.parse(JSON.stringify(beneficiariesDataStoreForm.beneficiaryInsures[indexRoot]))
        : [{ name: '', lastname: '', dni: '', benefitPercentage: '100', beneficiaryRelationship: '',errorsMessages: Array.from(errorsMessages) }]

    const thereIsMoreThanOneBeneficiary = useRef(false);
    const [beneficiaryFormData, setFormData] = useState<any>(getBeneficiaryState());
    const handleChange = (e: any, index: number) => {
        const newFormData = [...beneficiaryFormData];
        newFormData[index][e.target.name] = e.target.value;
        setFormData(newFormData)
    };

    const addInsured = () => {
        const newFormData = [...beneficiaryFormData, { name: '', lastname: '', dni: '', benefitPercentage: '', beneficiaryRelationship: '', errorsMessages: [] }];
        setFormData(newFormData);
    };

    const deleteInsured = (index: number) => {
        const choppedFormData = [...beneficiaryFormData];
        choppedFormData.splice(index, 1);
        const newFormData = choppedFormData.length > 1
            ?
                [...choppedFormData]
            :
                [{...choppedFormData[0], benefitPercentage: '100'}];
        setFormData(newFormData);
    };

    useEffect(() => {
        thereIsMoreThanOneBeneficiary.current = beneficiaryFormData.length > 1;
        onChange(beneficiaryFormData, indexRoot);
    }, [beneficiaryFormData]);

    useLayoutEffect(() => {
        (async() => {
            await dispatch(getBeneficiaryRelationshipTypesAsync());
        })();
    }, [dispatch])

    useEffect(() => {
        const selectOptions = [{value: '', name: ''}, ...options.map(({value, label}) => ({
            name: label,
            value,
        }))]
        setBeneficiaryRelationshipCombo(selectOptions)
    }, [options])

    const hasError = (item: any, name: string) => {
        return item.errorsMessages.filter((el: any) => el.field === name).length > 0;
    };
    
    const getError = (item: any, name: string) => {
    if(hasError(item, name)){
        return item.errorsMessages.filter((el: any) => el.field === name)[0].message;
    }
    else {
        return undefined;
    }
    };

    return (
        <div className="beneficiary-container mt-3">
            <div className="beneficiary-insured-subtitle">
                <span>{`${lang.insuredNumber}${indexRoot + 1}: `}<b>{`${name} ${lastname}`}</b>{` (${lang.insuredDni} ${dni})`}</span>
            </div>

            {beneficiaryFormData.length > 1 && (<p className="percentage-desc">{lang.benefitPercentageDescription}</p>)}
        
            {beneficiaryFormData.length > 0 && beneficiaryFormData.map((item: BeneficiaryInsure, index: number) => (
                <div className="row" key={index}>
                    <div className="col-md-3 col-12">
                        <Input
                            name="name"
                            label={lang.name}
                            onChange={(e: any) => handleChange(e, index)}
                            value={item.name}
                            type="text"
                            error={hasError(item, "name")}
                            errorMessage={getError(item, "name")}
                        />
                    </div>
                    <div className={`${beneficiaryFormData.length === 1 ? 'col-md-3' : 'col-md-2'} col-md-2 col-12`}>
                        <Input
                            name="lastname"
                            label={lang.lastname}
                            onChange={(e: any) => handleChange(e, index)}
                            value={item.lastname}
                            type="text"
                            error={hasError(item, "lastname")}
                            errorMessage={getError(item, "lastname")}
                        />
                    </div>
                    <div className="col-md-2 col-12">
                        <Input
                            name="dni"
                            label={lang.insuredDni}
                            onChange={(e: any) => handleChange(e, index)}
                            value={item.dni}
                            type="text"
                            error={hasError(item, "dni")}
                            errorMessage={getError(item, "dni")}
                        />
                    </div>
                    <div className={`${beneficiaryFormData.length === 1 ? 'col-md-4' : 'col-md-3'} col-12`}>
                        <Select 
                            name="beneficiaryRelationship"
                            value={item.beneficiaryRelationship}
                            label={lang.beneficiaryRelationship} 
                            options={beneficiaryRelationshipCombo}
                            onChange={(e: any) => handleChange(e, index)}
                            error={hasError(item, "beneficiaryRelationship")}
                            errorMessage={getError(item, "beneficiaryRelationship")}
                        />
                    </div>
                    {
                        beneficiaryFormData.length > 1 && (
                            <>
                                <div className={`${ window.innerWidth > 768 ? 'd-flex' : ''} col-md-2 col-12`}>
                                    <Input
                                        name="benefitPercentage"
                                        label={lang.benefitPercentage}
                                        onChange={(e: any) => handleChange(e, index)}
                                        value={item.benefitPercentage}
                                        type="number"
                                        error={hasError(item, "benefitPercentage")}
                                        errorMessage={getError(item, "benefitPercentage")}
                                    />
                                    {
                                        window.innerWidth > 768 && (
                                            <div className="col-md-2 col-2 d-flex justify-content-md-start justify-content-center">
                                                <img onClick={() => deleteInsured(index)} src={TrashIcon} alt="trash" className='trash-icon mb-4'></img>
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    window.innerWidth <= 768 && (
                                        <div className="col-md-2 col-12 d-flex justify-content-md-end justify-content-center">
                                            <Button className="col-md-12 col-10 mb-3 button-secondary-outline-ap" onClick={() => deleteInsured(index)}>{lang.deleteInsured}</Button>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            ))}
            <div className="row">
                <div className="col-md-2 col-12">
                    <Button onClick={() => addInsured()}>{lang.addInsured}</Button>
                </div>
                <div className="col-md-8 col-12"></div>
            </div>
        </div>
    );
};

export default BeneficiaryInsuredForm;
