// React
import { useEffect, useRef, useState } from 'react';

// Libraries
import { useHistory } from 'react-router-dom';

// Commons
import { PaymentDataForm } from '@experta/commons-v2.payment-data/dist/utils';
import { Button } from '@experta/commons-v2.button';
import { InfoHeader } from '@experta/commons-v2.info-header';
import { PaymentData } from '@experta/commons-v2.payment-data';
import { PaymentPrivacyBanner } from '@experta/commons-v2.payment-privacy-banner';
import { StepsPayment } from '@experta/commons-v2.steps-payment';

// Parts
import Insureds from '../../Parts/Forms/Insureds/insureds';
import PaymentInsuredApply from '../../Parts/Forms/PaymentInsuredApply/PaymentInsuredApply';

// Store
import { emmitQuoteAsync } from '../../Store/EmmitQuote/Actions';
import { getQuoteAsync } from '../../Store/Quote/Actions';
import { hideSpinner, showSpinner } from '../../Store/Spinner/Actions';
import { saveDataForm } from '../../Store/Data/Slice';
import { savePaymentFormData } from '../../Store/Payment/Slice';
import { selectActivitiesCombo } from '../../Store/Activities/Selectors';
import { selectCardsBrandsCombo } from '../../Store/CardsBrands/Selectors';
import { selectDataFormData } from '../../Store/Data/Selectors';
import { selectInitialFormData } from '../../Store/Forms/Selectors';
import { selectPaymentMethods } from '../../Store/PaymentMethods/Selectors';
import {
  selectQuote,
  selectQuoteHash,
  selectSelectedPlan,
} from '../../Store/Quote/Selectors';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';

// Services
import { getCBUValidator } from '../../Services';

// Utils
import { getTextFormatPrice } from '../Plans/utils';
import { usePaymentUtils } from './utils';

// Validators
import { paymentFormAsyncValidation } from './paymentFormAsyncValidation';

// Assets
import iconData from '../../Assets/Icons/ICONO_DATOS_white.svg';
import iconPayment from '../../Assets/Icons/ICONO_PAGO_white.svg';
import iconPlans from '../../Assets/Icons/ICONO_PLANES.svg';
import iconQuote from '../../Assets/Icons/ICONO_COTIZACION.svg';

// Languages
import * as lang from './lang.json';

// Styles
import './Payment.css';

/**
 * Interface used as props.
 * @name PaymentPageProps
 */
export interface PaymentPageProps {}

/**
 * Main component.
 * @name PaymentPage
 * @param {PaymentPageProps}
 * @returns {<PaymentPage />}
 */

interface clientData {
  beneficiaryFormData: {};
  insuredsFormData: Array<{
    birthDate: string;
    documentNumber: string;
    index: number;
    lastname: string;
    name: string;
    errors: [];
  }>;
  personalFormData: any;
}

const PaymentPage = ({}: PaymentPageProps) => {
  let history = useHistory();

  const { getPaymentMethodsImages } = usePaymentUtils();

  //Selectors
  const personalData: clientData = useAppSelector(selectDataFormData);
  const paymentMethods = useAppSelector(selectPaymentMethods);
  const cardCompanies = useAppSelector(selectCardsBrandsCombo);

  const quote = useAppSelector(selectQuote);
  const quoteHash = useAppSelector(selectQuoteHash);
  const selectedPlan = useAppSelector(selectSelectedPlan);

  const dispatch = useAppDispatch();
  const paymentDataRef = useRef();
  const paymentInsuredApplyRef = useRef();
  const insuredsDataRef = useRef();

  const formReducer = (state: any, event: any) => {
    return {
      ...state,
      [event.name]: event.value,
    };
  };

  const formatInsureds = (insuredsCount: number) => {
    const res = [];
    for (let i = 0; i < insuredsCount; i++) {
      res.push({
        index: i,
        name: personalData.insuredsFormData[i].name || '',
        lastname: personalData.insuredsFormData[i].lastname || '',
        documentNumber: personalData.insuredsFormData[i].documentNumber || '',
        birthDate: personalData.insuredsFormData[i].birthDate || '',
        errors: personalData.insuredsFormData[i].errors || [],
      });
    }
    return res;
  };

  const [showPersonalDataForm, setShowPersonalDataForm] =
    useState<boolean>(false);
  const [showInsuredDataForm, setShowInsuredDataForm] =
    useState<boolean>(false);
  const [paymentDataForm, setPaymentDataForm] = useState<PaymentDataForm>({
    paymentMethod: 'card',
    cardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    cardName: '',
    cardCompany: '',
    bankId: '',
    bankName: '',
    CBU: '',
  });

  const [personalFormData, setPersonalFormData] = useState(
    personalData?.personalFormData
  );
  const insuredDataFromStore =
    personalData &&
    personalData.insuredsFormData &&
    personalData.insuredsFormData.map((value) => [
      { title: 'NOMBRE Y APELLIDO', value: `${value.name} ${value.lastname}` },
      { title: 'DNI', value: value.documentNumber },
      { title: 'FECHA DE NACIMIENTO', value: value.birthDate },
    ]);

  const [insuredsFormData, setinsuredsFormData] = useState<any[]>(
    formatInsureds(personalData?.insuredsFormData.length)
  );

  const stepsPaymentParams = {
    currentStep: 3,
    stepsStyle: {
      color: 'white',
      bgColor: 'rgba(23, 160, 213, 0.7)',
      bgColorActive: 'rgba(23, 160, 213, 1)',
    },
    data: [
      { title: lang.stepPayment.quote, icon: iconQuote },
      { title: lang.stepPayment.plans, icon: iconPlans },
      { title: lang.stepPayment.payment, icon: iconData },
      { title: lang.stepPayment.final, icon: iconPayment },
    ],
  };

  const getInfoHeaderParams = () => {
    if (personalFormData?.tipoPersona === 'FISICA') {
      return {
        title: lang.infoHeader.title,
        insuredAssets: [
          { title: 'TIPO DE PERSONA', value: personalFormData.tipoPersona },
          { title: 'DNI', value: personalFormData.dni },
          {
            title: 'NOMBRE Y APELLIDO',
            value: `${personalFormData.name} ${personalFormData.lastname}`,
          },
          {
            title: 'DOMICILIO',
            value: `${personalFormData.street} ${personalFormData.streetNumber} ${personalFormData.floor}${personalFormData.number} (${personalFormData.zipCode})`,
          },
        ],
      };
    } else {
      return {
        title: lang.infoHeader.title,
        insuredAssets: [
          { title: 'TIPO DE PERSONA', value: personalFormData?.tipoPersona },
          { title: 'CUIT', value: personalFormData?.cuit },
          { title: 'RAZON SOCIAL', value: `${personalFormData?.socialReason}` },
          {
            title: 'DOMICILIO',
            value: `${personalFormData?.street} ${personalFormData?.streetNumber} ${personalFormData?.floor}${personalFormData?.number} (${personalFormData?.zipCode})`,
          },
        ],
      };
    }
  };

  let infoHeaderParams = getInfoHeaderParams();

  useEffect(() => {
    if (cardCompanies && cardCompanies.length === 1) {
      setPaymentDataForm((prevState) => ({
        ...prevState,
        cardCompany: cardCompanies[0].value,
      }));
    }
  }, [cardCompanies]);

  useEffect(() => {
    infoHeaderParams = getInfoHeaderParams();
  }, [personalFormData]);

  const handleChange = (formData: PaymentDataForm) => {
    /* This service call fill the bank name through the PaymentFormData state
    into PaymentData component field */
    if (formData.CBU?.length === 22) {
      dispatch(showSpinner());
      getCBUValidator()
        ?.validateCBU(formData.CBU)
        .then(({ data }) =>
          setPaymentDataForm({
            ...formData,
            bankId: data?.banco.value,
            bankName: data?.banco.label,
          })
        )
        .catch((error) => console.error(error))
        .finally(() => dispatch(hideSpinner()));
    } else {
      setPaymentDataForm(formData);
    }
  };

  const handleSubmit = async () => {
    dispatch(showSpinner());
    const isValid = await validateStep();
    if (isValid) {
      dispatch(savePaymentFormData(paymentDataForm));
      await dispatch(
        emmitQuoteAsync({
          formData: initialFormData,
          personalData: personalData,
          paymentData: paymentDataForm,
          quoteHash,
        })
      );
      await dispatch(getQuoteAsync(quoteHash)); //update quote data after emmit
      history.push(`/gracias?hashId=${quoteHash}`);
    }
    dispatch(hideSpinner());
  };

  const handleSavePersonalData = async (data: any) => {
    const isFormValid = (paymentInsuredApplyRef.current as any).validateForm();
    if (isFormValid) {
      const newPersonalData = { ...personalData };
      newPersonalData.personalFormData = { ...data };
      await dispatch(saveDataForm(newPersonalData));
      setShowPersonalDataForm(!showPersonalDataForm);
      setPersonalFormData(newPersonalData.personalFormData);
    }
  };

  const handleSaveInsuredData = async () => {
    const isFormValid = (insuredsDataRef.current as any).validateForm();
    if (isFormValid) {
      const newPersonalData = { ...personalData };
      const newInsuredFormData = [...insuredsFormData];
      newPersonalData.insuredsFormData = newInsuredFormData;
      await dispatch(saveDataForm(newPersonalData));
      setShowInsuredDataForm(!showInsuredDataForm);
    }
  };

  const activities = useAppSelector(selectActivitiesCombo);
  const initialFormData = useAppSelector(selectInitialFormData);
  const valuesInfoHeader = [
    {
      title: 'Actividad',
      value: activities?.filter(
        (activity) => activity?.value === initialFormData?.activity
      )[0]?.name,
    },
    { title: 'Personas Aseguradas', value: initialFormData?.quantity },
    { title: 'Desde', value: initialFormData?.dateFrom },
    { title: 'Hasta', value: initialFormData?.dateTo },
    { title: 'Plan', value: selectedPlan?.nombrePlan },
    {
      title: 'Precio',
      value: getTextFormatPrice({
        validity: quote?.vigencia.tipoVigencia,
        plan: selectedPlan,
      }),
    },
  ];

  const validateStep = async () => {
    let result = await (paymentDataRef?.current as any).validateForm();
    return result;
  };

  return (
    <div>
      <div className='info-header-wrapper'>
        <InfoHeader
          title={lang.infoHeaderTitle}
          insuredAssets={valuesInfoHeader}
        />
      </div>
      <StepsPayment
        currentStep={stepsPaymentParams.currentStep}
        style={stepsPaymentParams.stepsStyle}
        steps={stepsPaymentParams.data}
      />

      <div className='payment-container p-3 p-lg-5 mb-3'>
        <h3 className='payment-title my-3'>{lang.paymenTitle}</h3>
        <p className='payment-description my-3 mb-5'>
          {lang.paymentDescription}
        </p>

        <h4 className='payment-subtitle my-3'>{lang.paymentSubtitle}</h4>

        {showPersonalDataForm ? (
          <div className='my-3'>
            <PaymentInsuredApply
              ref={paymentInsuredApplyRef}
              onSubmit={handleSavePersonalData}
              formValues={personalFormData}
            />
          </div>
        ) : (
          <>
            <div className='my-3'>
              <InfoHeader insuredAssets={infoHeaderParams.insuredAssets} />
            </div>
            <div className='payment-button-wrapper-modify my-3'>
              <button
                className='payment-button-modify mb-5 mt-md-4'
                onClick={() => setShowPersonalDataForm(!showPersonalDataForm)}
              >
                {lang.modifyButton}
              </button>
            </div>
          </>
        )}

        <h4 className='payment-subtitle my-3'>{lang.paymentSubtitle2}</h4>

        {showInsuredDataForm ? (
          <div className='my-3'>
            <Insureds
              ref={insuredsDataRef}
              values={insuredsFormData}
              count={insuredsFormData.length}
              onChange={(data: any) => setinsuredsFormData(data)}
            />
            <Button
              className={'button-primary-solid-ap'}
              onClick={handleSaveInsuredData}
            >
              {lang.saveButton}
            </Button>
          </div>
        ) : (
          <>
            {insuredDataFromStore &&
              insuredDataFromStore.map((insuredPerson, index) => {
                return (
                  <div key={index} className='my-3'>
                    <InfoHeader insuredAssets={insuredPerson} />
                  </div>
                );
              })}

            <div className='payment-button-wrapper-modify my-3'>
              <button
                className='payment-button-modify mb-5 mt-md-4'
                onClick={() => setShowInsuredDataForm(!showInsuredDataForm)}
              >
                {lang.modifyButton}
              </button>
            </div>
          </>
        )}

        <h4 className='payment-subtitle my-3'>{lang.resolution}</h4>
        <p className='payment-description my-3 mb-5'>
          {lang.resolutionDescription}
          <a>{lang.resololutionLink}</a>
        </p>
        <div className='my-3'>
          { (cardCompanies.length > 1 || (cardCompanies.length == 1 && paymentDataForm.cardCompany)) && paymentMethods.length && (
            <PaymentData
              cardCompannies={cardCompanies}
              paymentMethods={paymentMethods}
              formData={paymentDataForm}
              handleChange={handleChange}
              ref={paymentDataRef}
              validateMethod={paymentFormAsyncValidation}
            />
          )}
        </div>
        <div className='row'>
          <div className='col-md-6 d-flex justify-content-md-start justify-content-center mb-3'>
            <Button
              className='button-back button-secondary-outline-ap'
              onClick={() => history.push(`/datos?hashId=${quoteHash}`)}
            >
              {lang.backButton}
            </Button>
          </div>
          <div className='col-md-6 d-flex justify-content-md-end justify-content-center'>
            <Button
              onClick={handleSubmit}
              className='button-next button-primary-solid-ap'
            >
              {lang.payButton}
            </Button>
          </div>
        </div>
      </div>
      <div className='payment-banner-container my-3'>
        <PaymentPrivacyBanner paymentMethodsImgs={getPaymentMethodsImages()} />
      </div>
    </div>
  );
};

export default PaymentPage;
