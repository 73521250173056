import './Insureds.css'
import * as lang from "./lang.json";
import {Input} from '@experta/commons-v2.input';

/**
 * Interface used as props.
 * @name InsuredFormProps
 */
 export interface InsuredFormProps {
  index: number,
  onChange: (data: any, index: number) => void,
  formValues: any
};

/**
 * Main component.
 * @name InsuredForm
 * @param {InsuredFormProps}
 * @returns {<InsuredForm />}
 */
const InsuredForm = ({ index, onChange = (data: any, index: number) => {}, formValues }: InsuredFormProps) => {

  const handleChange = (e: any) => {
    const newState = {...formValues, [e.target.name]: e.target.value };
    onChange(newState, index);
  };

  const hasError = (name: string) => {
    return formValues.errorsMessages?.filter((el: any) => el.field === name).length > 0;
  };

  const getError = (name: string) => {
    if(hasError(name)){
      return formValues.errorsMessages?.filter((el: any) => el.field === name)[0].message;
    }
    else {
      return undefined;
    }
  };

  return (
    <div className="insured-container mb-5">
      <div className="insured-subtitle insured-subtitle-mobile">
          <span>{lang.subtitle + (index + 1)}</span>
      </div>

      <div className="row">
        <div className="col-md-6 col-12">
          <Input
            name="name"
            label={lang.name}
            onChange={handleChange}
            value={formValues.name}
            type="text"
            error={hasError("name")}
            errorMessage={getError("name")}
          />
        </div>
        <div className="col-md-6 col-12">
          <Input
            name="lastname"
            label={lang.lastname}
            onChange={handleChange}
            value={formValues.lastname}
            type="text"
            error={hasError("lastname")}
            errorMessage={getError("lastname")}
          />
        </div>
        <div className="col-md-6 col-12">
          <Input
            name="documentNumber"
            label={lang.documentNumber}
            onChange={handleChange}
            value={formValues.documentNumber}
            type="text"
            error={hasError("documentNumber")}
            errorMessage={getError("documentNumber")}
          />
        </div>
        <div className="col-md-6 col-12">
          <Input
            name="birthDate"
            label={lang.birthDate}
            onChange={handleChange}
            value={formValues.birthDate}
            type="date"
            error={hasError("birthDate")}
            errorMessage={getError("birthDate")}
          />
          <p className="dateTextLegal m-0">{lang.dateTextLegal}</p>
        </div>
      </div>
    </div>
  );
};

export default InsuredForm;
