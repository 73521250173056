import API from "./api";

export class ActivitiesService {
  public getActivities = async (planID: any) => {

    const payload = {
      "tipoPlan": planID
    };
    
    const res = API.get("/negocio/actividades-asegurado/opciones", { params: payload });
    return res.then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  };
}
