import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBeneficiaryRelationshipService } from '../../Services';

const createBaseAsyncMethod = (actionName: string, serviceMethod: any) => {
  return createAsyncThunk(
    actionName,
    async (_, { rejectWithValue }: any) => {
      const response = await serviceMethod();
      /* manipular response y checkeo de errores */
      
        if(response?.data && response?.status === 200){
          return response.data;
        }
        else {
          return rejectWithValue({ errorMessage: 'Error al obtener los Beneficiary Relationship Types'})
        }
      
    }
  );
}

export const getBeneficiaryRelationshipTypesAsync = createBaseAsyncMethod('beneficiaryRelationship/fetchBeneficiaryRelationsipTypes',  getBeneficiaryRelationshipService()?.getBeneficiaryRelationshipTypes);