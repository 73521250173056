import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPlansType } from "../../Services";

export const getPlansTypeAsync = createAsyncThunk(
  "plansType/fetchGetPlansType",
  async (_, { rejectWithValue }: any) => {
    const response = await getPlansType()?.getPlansType();
    if (response?.status === 200 && response?.data) {
      return response.data;
    } else {
      return rejectWithValue({ errorMessage: "Error al obtener los planes" });
    }
  }
);
