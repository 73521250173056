import { Validations } from "@experta/commons-v2.layout";
import moment from "moment";
import * as lang from "./lang.json"

/**
 * Interface used as form props.
 * @name InfoHeaderFormProps
 */
 export interface InfoHeaderFormProps {
  activity: string,
  quantity: string,
  dateFrom: string,
  dateTo: string,
};

export const getQuantityValues = () => {
  return [
    {
      name: "",
      value: "",
    },
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "2",
    },
    {
      name: "3",
      value: "3",
    },
    {
      name: "4",
      value: "4",
    },
    {
      name: "5",
      value: "5",
    },
    {
      name: "6",
      value: "6",
    },
    {
      name: "7",
      value: "7",
    },
    {
      name: "8",
      value: "8",
    },
    {
      name: "9",
      value: "9",
    },
    {
      name: "10",
      value: "10",
    },
  ];
};

/**
 * InfoHeader form validations.
 * @name validateForm
 */
export const validateForm = (localFormData: InfoHeaderFormProps) => {
  let errorsMessages = [];

  if (!(Validations.required(localFormData.activity))) {
    errorsMessages.push({field: "activity", message: lang.errors.required});
  }

  if (!(Validations.required(localFormData.quantity))) {
    errorsMessages.push({field: "quantity", message: lang.errors.required});
  }

  if (!(Validations.required(localFormData.dateFrom))) {
    errorsMessages.push({field: "dateFrom", message: lang.errors.required});
  } else if (!(moment(localFormData.dateFrom, lang.formatDate).isBefore(moment(localFormData.dateTo, lang.formatDate)))) {
    errorsMessages.push({field: "dateFrom", message: lang.errors.posterior});
  }

  if (!(Validations.required(localFormData.dateTo))) {
    errorsMessages.push({field: "dateTo", message: lang.errors.required});
  }

  return { errorsMessages: errorsMessages };
};