import API from "./api";

export class PaymentMethodsService {

    public getPaymentMethods = async () => {
        const res = API.get('/negocio/medios-pago/opciones');
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err
        })
    };

}