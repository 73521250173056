// React
import { useEffect, useRef, useState } from 'react';

// Libraries
import { useHistory } from 'react-router-dom';

// Components and Commons
import { Banner } from '@experta/commons-v2.banner';
import { formQuoteAsyncValidation } from './formQuoteAsyncValidation';
import { PlansInfo } from '@experta/commons-v2.plans-info';
import { PriceCard } from '@experta/commons-v2.price-card';
import { Steps } from '@experta/commons-v2.steps';
import { Subbanner } from '@experta/commons-v2.subbanner';
import { Subtitle } from '@experta/commons-v2.subtitle';
import FormQuote from '../../Components/FormQuote/FormQuote';

// Store
import {
  generateInsuredsFormFields,
  saveInitialFormData,
} from '../../Store/Forms/Slice';
import { getActivitiesAsync } from '../../Store/Activities/Actions';
import { getInsuredServiceAsync } from '../../Store/Insured/Actions';
import { getPlansTypeAsync } from '../../Store/PlansType/Actions';
import { getQuoteHashAsync, getQuoteAsync } from '../../Store/Quote/Actions';
import { getValidityTypesAsync } from '../../Store/ValidityTypes/Actions';
import { hideSpinner, showSpinner } from '../../Store/Spinner/Actions';
import { selectActivitiesCombo } from '../../Store/Activities/Selectors';
import { selectInsuredOptions } from '../../Store/Insured/Selectors';
import { selectPlansType } from '../../Store/PlansType/Selectors';
import { selectQuoteHash } from '../../Store/Quote/Selectors';
import { useAppSelector, useAppDispatch } from '../../Store/hooks';

// Utils
import { useHomeUtils } from './utils';

// Styles
import './Home.css';
import { selectValidityTypes } from '../../Store/ValidityTypes/Selectors';

/**
 * Interface used as props.
 * @name HomePageProps
 */
export interface HomePageProps {}

/**
 * Main component.
 * @name HomePage
 * @param {HomePageProps}
 * @returns {<HomePage />}
 */

const HomePage = ({}: HomePageProps) => {
  let history = useHistory();
  const dispatch = useAppDispatch();

  const {
    paramsBanner,
    getParamsPlansInfo,
    paramsPriceCard,
    paramsSteps,
    paramsSubBanner,
    paramsSubtitle,
  } = useHomeUtils();

  const activities = useAppSelector(selectActivitiesCombo);
  const plansType = useAppSelector(selectPlansType);
  const validitiesType = useAppSelector(selectValidityTypes);
  const insuredOptions = useAppSelector(selectInsuredOptions);
  const quoteHash = useAppSelector(selectQuoteHash);
  const [formData, setFormData] = useState<any>({});
  const quoteFormRef = useRef();
  const paramsPlansInfo = getParamsPlansInfo();

  const loadFormValues = async () => {
    await dispatch(showSpinner());
    await dispatch(getPlansTypeAsync());
    await dispatch(getActivitiesAsync('ENLATADO_LABORAL'));
    await dispatch(getInsuredServiceAsync());
    await dispatch(getValidityTypesAsync());
    await dispatch(hideSpinner());
  };

  useEffect(() => {
    loadFormValues();
  }, [dispatch]);

  useEffect(() => {
    if (quoteHash) {
      dispatch(showSpinner());
      dispatch(getQuoteAsync(quoteHash)).then(() => {
        history.push(`/planes?hashId=${quoteHash}`);
        dispatch(hideSpinner());
      });
    }
  }, [quoteHash]);

  const onSubmitForm = async () => {
    dispatch(showSpinner());
    const isValid = await validateQuoteForm();
    if (isValid) {
      await dispatch(generateInsuredsFormFields(formData.quantity));
      await dispatch(saveInitialFormData(formData));
      await dispatch(getQuoteHashAsync({ formData: formData }));
    }
    dispatch(hideSpinner());
  };

  const validateQuoteForm = async (): Promise<boolean> => {
    const errorMessages = await (quoteFormRef.current as any).validateForm();
    if (errorMessages.length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Banner
        imageDesktopURL={paramsBanner.imageDesktopURL}
        imageMobileURL={paramsBanner.imageMobileURL}
        title={paramsBanner.title}
        titleColor={paramsBanner.titleColor}
      />
      <div className='home-container px-md-0 px-lg-3 px-xl-5'>
        <div className='home-first-column'>
          <PriceCard info={paramsPriceCard} style={paramsPriceCard.style} />
          <PlansInfo
            title={paramsPlansInfo.title}
            description={paramsPlansInfo.description}
            mobileInfo={paramsPlansInfo.mobileInfo}
            plans={paramsPlansInfo.plans}
          />
        </div>
        <div className='home-second-column'>
          <div className='subtitle-component-wrapper'>
            <Subtitle
              message={paramsSubtitle.message}
              color={paramsSubtitle.color}
            />
          </div>
          <FormQuote
            ref={quoteFormRef}
            plansType={plansType}
            validitiesType={validitiesType}
            activities={activities}
            insuredOptions={insuredOptions}
            onChange={(data: any) => setFormData(data)}
            onSubmit={() => onSubmitForm()}
            validateMethod={formQuoteAsyncValidation}
          />
          {/* {activities && <DataPage></DataPage>} */}
        </div>
        <div className='full-width'>
          <Subbanner {...paramsSubBanner} />
        </div>
        <div className='full-width'>
          <Steps stepsList={paramsSteps.stepsList} title={paramsSteps.title} />
        </div>
      </div>
    </>
  );
};

export default HomePage;
