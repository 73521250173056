import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQuoteService } from '../../Services';

const createBaseAsyncMethod = (actionName: string, serviceMethod: any) => {
  return createAsyncThunk(
    actionName,
    async (data: any, { rejectWithValue }: any) => {
      const response = await serviceMethod(data);
        if (response?.data?.status === 400) {
          return rejectWithValue({ errorMessage: response.data.message });
        } 
        else if (response.status === 200 || response.status === 201) {
          return response.data;
        }
        else {
          return rejectWithValue({ errorMessage: 'Error al obtener los planes'})
        }
    }
  );
}

export const getQuoteHashAsync = createBaseAsyncMethod('plans/fetchQuoteHash',  getQuoteService()?.quote);
export const getQuoteAsync = createBaseAsyncMethod('plans/fetchQuote',  getQuoteService()?.getQuote);
export const updateQuoteAsync = createBaseAsyncMethod('plans/fetchUpdateQuote',  getQuoteService()?.updateQuote);
export const selectedPlanAsync = createBaseAsyncMethod('plans/selectedPlanQuote',  getQuoteService()?.selectedPlan);
//export const getPlansAsync = createBaseAsyncMethod('plans/fetchGetQuote',  getQuoteService()?.getPlans);
