import { selectEnvVariables } from "../../Store/Variables/selectors";
import { useAppSelector } from "../../Store/hooks";


export const useHomeUtils = () => {

    const variables = useAppSelector(selectEnvVariables) as any;

    /**
     * Params to use in banner component.
     * @name paramsBanner
    */
    const paramsBanner = {
        imageDesktopURL: variables.REACT_APP_HOME_BANNER_DESKTOP,
        imageMobileURL: variables.REACT_APP_HOME_BANNER_MOBILE,
        title: variables.REACT_APP_HOME_BANNER_TITLE,
        titleColor: variables.REACT_APP_HOME_BANNER_TITLE_COLOR,
    };

    const getButtonLinks = () => {
        const buttonLinks = [];
        if(variables.REACT_APP_HOME_SUBBANNER_BUTTON_LINK_1_IMAGE && variables.REACT_APP_HOME_SUBBANNER_BUTTON_LINK_1_LINK) {
            buttonLinks.push(
                {
                    imageUrl: variables.REACT_APP_HOME_SUBBANNER_BUTTON_LINK_1_IMAGE,
                    link: variables.REACT_APP_HOME_SUBBANNER_BUTTON_LINK_1_LINK,
                },
            )
        }
        
        if(variables.REACT_APP_HOME_SUBBANNER_BUTTON_LINK_2_IMAGE && variables.REACT_APP_HOME_SUBBANNER_BUTTON_LINK_2_LINK) {
            buttonLinks.push(
                {
                    imageUrl: variables.REACT_APP_HOME_SUBBANNER_BUTTON_LINK_2_IMAGE,
                    link: variables.REACT_APP_HOME_SUBBANNER_BUTTON_LINK_2_LINK,
                },
            )
        }
        
        return buttonLinks;
    }

    /**
    * Params to use in price-card component.
    * @name paramsPriceCard
    */
    const paramsPriceCard = {
        logo: variables.REACT_APP_HOME_PRICECARD_LOGO,
        title: variables.REACT_APP_HOME_PRICECARD_TITLE,
        quoteDesc: variables.REACT_APP_HOME_PRICECARD_QUOTE_DESC,
        mobileText: variables.REACT_APP_HOME_PRICECARD_MOBILE_TEXT,
        price: variables.REACT_APP_HOME_PRICE,
        style: {
            color: variables.REACT_APP_HOME_PRICECARD_STYLE_COLOR,
            backgroundColor: variables.REACT_APP_ACCENT_COLOR,
            backgroundImage: variables.REACT_APP_HOME_PRICECARD_STYLE_BG_IMAGE,
            backgroundRepeat: variables.REACT_APP_HOME_PRICECARD_STYLE_BG_REPEAT,
            backgroundPosition: variables.REACT_APP_HOME_PRICECARD_STYLE_BG_POSITION,
            backgroundSize: variables.REACT_APP_HOME_PRICECARD_STYLE_BG_SIZE,
            minHeight: variables.REACT_APP_HOME_PRICECARD_STYLE_MIN_HEIGHT
        },
    };

    /**
     * Params to use in plans-info component.
     * @name paramsPlansInfo
     */
    const getParamsPlansInfo = () => {

        let resp:{
            title: string,
            description: string,
            mobileInfo: { title:string, quoteDesc:string, price:string },
            plans: Array<{ title: string,  description: string }>
        } = {
            title: variables.REACT_APP_HOME_PLANSINFO_TITLE,
            description: variables.REACT_APP_HOME_PLANSINFO_DESCRIPTION,
            mobileInfo: {
                title: variables.REACT_APP_HOME_PLANSINFO_TITLE_MOBILE,
                quoteDesc: variables.REACT_APP_HOME_PLANSINFO_QUOTE_DESCRIPTION_MOBILE,
                price: variables.REACT_APP_HOME_PRICE,
            },
            plans: [],
        }

        if(variables.REACT_APP_HOME_PLANSINFO_PLANS_1_TITLE && variables.REACT_APP_HOME_PLANSINFO_PLANS_1_DESCRIPTION){
            resp.plans.push({
                title: variables.REACT_APP_HOME_PLANSINFO_PLANS_1_TITLE,
                description: variables.REACT_APP_HOME_PLANSINFO_PLANS_1_DESCRIPTION,
            })
        }
        if(variables.REACT_APP_HOME_PLANSINFO_PLANS_2_TITLE && variables.REACT_APP_HOME_PLANSINFO_PLANS_2_DESCRIPTION){
            resp.plans.push({
                title: variables.REACT_APP_HOME_PLANSINFO_PLANS_2_TITLE,
                description: variables.REACT_APP_HOME_PLANSINFO_PLANS_2_DESCRIPTION,
            })
        }
        if(variables.REACT_APP_HOME_PLANSINFO_PLANS_3_TITLE && variables.REACT_APP_HOME_PLANSINFO_PLANS_3_DESCRIPTION){
            resp.plans.push({
                title: variables.REACT_APP_HOME_PLANSINFO_PLANS_3_TITLE,
                description: variables.REACT_APP_HOME_PLANSINFO_PLANS_3_DESCRIPTION,
            })
        }
        if(variables.REACT_APP_HOME_PLANSINFO_PLANS_4_TITLE && variables.REACT_APP_HOME_PLANSINFO_PLANS_4_DESCRIPTION){
            resp.plans.push({
                title: variables.REACT_APP_HOME_PLANSINFO_PLANS_4_TITLE,
                description: variables.REACT_APP_HOME_PLANSINFO_PLANS_4_DESCRIPTION,
            })
        }

        return resp;

    };

    /**
     * Params to use in subtitle component.
     * @name paramsSubtitle
     */
    const paramsSubtitle = {
        color: variables.REACT_APP_ACCENT_COLOR,
        message: variables.REACT_APP_HOME_SUBTITLE_TEXT,
    };


    /**
     * Params to use in subbanner component.
     * @name paramsSubBanner
     */
    const paramsSubBanner = {
        desktopImage: variables.REACT_APP_HOME_SUBBANNER_DESKTOP_IMAGE,
        mobileImage: variables.REACT_APP_HOME_SUBBANNER_MOBILE_IMAGE,
        textColor: variables.REACT_APP_HOME_SUBBANNER_TEXT_COLOR,
        maxSubbannerHeight: variables.REACT_APP_HOME_SUBBANNER_MAX_HEIGHT,
        maxSubbannerContentWidth: variables.REACT_APP_HOME_SUBBANNER_MAX_CONTENT_WIDTH,
        title: variables.REACT_APP_HOME_SUBBANNER_TITLE,
        subtitle: variables.REACT_APP_HOME_SUBBANNER_SUBTITLE,
        buttonLinks: getButtonLinks(),
    };



    /**
     * Params to use in steps component.
     * @name paramsSteps
     */
    const paramsSteps = {
        title: variables.REACT_APP_HOME_STEPS_TITLE,
        stepsList: [
            {
                text: variables.REACT_APP_HOME_STEPS_1_TEXT,
                color: variables.REACT_APP_ACCENT_COLOR,
                icon: variables.REACT_APP_HOME_STEPS_1_ICON,
            },
            {
                text: variables.REACT_APP_HOME_STEPS_2_TEXT,
                color: variables.REACT_APP_HOME_STEPS_2_BG_COLOR,
                icon: variables.REACT_APP_HOME_STEPS_2_ICON
            },
            {
                text: variables.REACT_APP_HOME_STEPS_3_TEXT,
                color: variables.REACT_APP_HOME_STEPS_3_BG_COLOR,
                icon: variables.REACT_APP_HOME_STEPS_3_ICON
            }
        ]
    }

    return {
        paramsSteps,
        paramsSubBanner,
        paramsBanner,
        paramsPriceCard,
        paramsSubtitle,
        getParamsPlansInfo
    }

};
