import { PaymentDataForm } from "@experta/commons-v2.payment-data/dist/utils";
import { PersonalDataFormProps } from "@experta/commons-v2.personal-data/dist/utils";
import { InitialDataFormProps } from "../../Components/FormQuote/utils";
import { RootState } from "../store";
export const selectInitialFormData = (state: RootState): InitialDataFormProps => state.forms.initialForm as InitialDataFormProps;
export const selectPersonalFormData = (state: RootState): PersonalDataFormProps => state.forms.personalData as PersonalDataFormProps;
export const selectPoliticallyExposedFormData = (state: RootState) => state.forms.politicallyExposedData;
export const selectBeneficiaryFormData = (state: RootState) => state.forms.beneficiaryFormData;
export const selectInsuredsFormData = (state: RootState) => state.forms.insuredsFormData;
export const selectPaymentFormData = (state: RootState): PaymentDataForm => state.forms.paymentData as PaymentDataForm;
export const selectObligatedSubjectFormData = (state: RootState) => state.forms.obligatedSubjectData;