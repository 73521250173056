import { createSlice } from '@reduxjs/toolkit';
import {getActivitiesAsync} from './Actions';

export interface ActivitiesValues {
  value: string; 
  label: string; 
}

export interface ActivitiesState {
  status: 'idle' | 'loading' | 'failed';
  activities: Array<ActivitiesValues>;
  errorMessage: string | undefined;
}

const initialState: ActivitiesState = {
  status: 'idle',
  activities: [],
  errorMessage: '',
};


export const activitiesSlice = createSlice({
  name: 'Activities',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActivitiesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getActivitiesAsync.rejected, (state, action: any) => {
        state.status = 'failed';
        state.activities = [];
        state.errorMessage = action.payload.errorMessage;
      })
      .addCase(getActivitiesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.activities = action.payload;
        state.errorMessage = ''
      })
  },
});

export default activitiesSlice.reducer;
