import { createSlice } from '@reduxjs/toolkit';
// import {getTokenAsync} from './Actions';

export interface SpinnerState {
  status: 'idle' | 'loading' | 'failed';
  requests: number;
  message?: string;
}

const initialState: SpinnerState = {
  status: 'idle',
  requests: 0,
  message: undefined
};


export const spinnerSlice = createSlice({
  name: 'Spinner',
  initialState,
  reducers: {
    showSpinner: (state, action) => {
      state.requests += 1;
      state.message = action.payload;
    },
    hideSpinner: (state) => {
      state.requests -= state.requests == 0 ? 0 : 1;
    }
  },
  extraReducers: (builder) => {
    /* None */
  }
});

export default spinnerSlice.reducer;
