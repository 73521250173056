import { selectEnvVariables } from "../../Store/Variables/selectors";
import { useAppSelector } from "../../Store/hooks";


export const getCoverages = (
  coverages: {
    codigoCobertura: string;
    descripcion: string;
    montoAsegurado: number;
    nombreCobertura: string;
  }[]
) => {
  const coveragesArray = coverages.map((item) => {
    return {
      name: item.nombreCobertura,
      value: item.montoAsegurado,
      id: item.codigoCobertura,
    };
  });
  return coveragesArray;
};


export const getTaxConditionValue = (taxCondition: string) => {
  switch (taxCondition) {
    case "EXCENTO":
      return "4";
    case "RESPONSABLE_INSCRIPTO":
      return "1";
    case "CONSUMIDOR_FINAL":
      return "5";
    case "MONOTRIBUTISTA":
      return "6";
    default:
      return "";
  }
};

export const getBeneficiaryType = (beneficiary: string) => {
  switch (beneficiary) {
    case "CONTRATANTE":
      return "1";
    case "EMPRESA":
      return "2";
    case "FISICA":
      return "3";
    default:
      return "";
  }
};

export const getBeneficiaryPercentage = (count: number, index: number) => {
  let percentage = 0;
  switch (count) {
    case 1:
      percentage = 100;
      break;
    case 2:
      percentage = 50;
      break;
    case 3:
      percentage = 33;
      break;
    case 4:
      percentage = 25;
      break;
  }
  if (percentage === 33 && index === 2) {
    percentage = 34;
  }
  return percentage;
};



export const getTextFormatPrice = ({validity, plan}: any) => {
  let message = "";
  const formatPrice = plan?.precioPorCuota && plan?.precioPorCuota.toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2
  });
  
  if (validity === "ANUAL") {
    message = `${formatPrice} por mes`;
  }
  if (validity === "MENSUAL") {
    message = `${formatPrice}`;
  }
  if(validity === "PERSONALIZADO") {
    message = plan.cuotas === 1 ? `${plan.cuotas} cuota de ${formatPrice}` : `${plan.cuotas} cuotas de ${formatPrice}`;
  }
  return message;
}

export const usePlansUtils = () => {

  const variables = useAppSelector(selectEnvVariables) as any;

  const paramsPlans = {
    legalText: variables.REACT_APP_PLANS_LEGALTEXT,
  };


  return {
    paramsPlans
  }

};