import API from "./api";

export class TakerActivitiesService {

    public getTakerActivities = async () => {
        const res = API.get('/negocio/actividades-tomador/opciones');
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err;
        })
    };

}