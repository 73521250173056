import { PaymentDataForm } from "@experta/commons-v2.payment-data/dist/utils";
import { PersonalDataFormProps } from "@experta/commons-v2.personal-data/dist/utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialDataFormProps } from "../../Components/FormQuote/utils";
import { obligatedSubjectFormData, tycFormData } from "../../Pages/Data/utils";
import { BeneficiaryInsuredForm } from "../../Parts/Forms/Beneficiary/BeneficiaryInsuredForm";
import { BeneficiaryForm } from "../../Parts/Forms/Beneficiary/utils";
import { InsuredValueProps } from "../../Parts/Forms/Insureds/insureds";
import initialFormStates from "./utils";

export interface FormState {
  initialForm: Partial<InitialDataFormProps> | null;
  personalData: Partial<PersonalDataFormProps>;
  politicallyExposedData: Partial<tycFormData>;
  insuredsFormData: Array<InsuredValueProps>;
  beneficiaryFormData: BeneficiaryForm;
  paymentData: Partial<PaymentDataForm>;
  obligatedSubjectData: Partial<obligatedSubjectFormData>;
}

const initialState: FormState = {
  initialForm: null,
  personalData: initialFormStates.personalData,
  politicallyExposedData: initialFormStates.politicallyExposed,
  insuredsFormData: [],
  beneficiaryFormData: initialFormStates.beneficiaryData,
  paymentData: initialFormStates.paymentData,
  obligatedSubjectData: initialFormStates.obligatedSubject,
};

export const formsSlice = createSlice({
  name: "Forms",
  initialState,
  reducers: {
    saveInitialFormData: (
      state,
      action: PayloadAction<FormState["initialForm"]>
    ) => {
      state.initialForm = action.payload;

      // Preloads personal data form with initial form editable data
      state.personalData = {
        ...state.personalData,
        name: action.payload?.firstName,
        lastname: action.payload?.lastName,
        email: action.payload?.email,
        areaCode: action.payload?.prefix,
        phone: action.payload?.phone,
      };
    },
    savePersonalFormData: (
      state,
      action: PayloadAction<FormState["personalData"]>
    ) => {
      state.personalData = action.payload;
    },
    generateInsuredsFormFields: (state, action: PayloadAction<number>) => {
      const res = [];
      const insuredsCount = action.payload;
      const baseObj = {
        name: "",
        lastname: "",
        documentNumber: "",
        birthDate: "",
        errorsMessages: [],
      };

      for (let i = 0; i < insuredsCount; i++) {
        res.push({ ...baseObj, index: i });
      }

      state.insuredsFormData = res;
    },
    saveInsuredsFormData: (
      state,
      action: PayloadAction<FormState["insuredsFormData"]>
    ) => {
      state.insuredsFormData = action.payload;
    },
    saveBeneficiaryFormData: (
      state,
      action: PayloadAction<FormState["beneficiaryFormData"]>
    ) => {
      state.beneficiaryFormData = action.payload;
    },
    savePoliticallyExposedFormData: (
      state,
      action: PayloadAction<FormState["politicallyExposedData"]>
    ) => {
      state.politicallyExposedData = action.payload;
    },
    savePaymentFormDataStore: (
      state,
      action: PayloadAction<FormState["paymentData"]>
    ) => {
      state.paymentData = action.payload;
    },
    saveObligatedSubjectFormData: (
      state,
      action: PayloadAction<FormState["obligatedSubjectData"]>
    ) => {
      state.obligatedSubjectData = action.payload;
    },
    clearFormStates: (state) => {
      state = initialState;
    },
  },
});

export const {
  saveInitialFormData,
  savePersonalFormData,
  generateInsuredsFormFields,
  saveInsuredsFormData,
  saveBeneficiaryFormData,
  savePoliticallyExposedFormData,
  savePaymentFormDataStore,
  clearFormStates,
  saveObligatedSubjectFormData,
} = formsSlice.actions;

export default formsSlice.reducer;
