import API from "./api";

export class TaxConditionsService {
  public getTaxConditions = async (tipoPersona: "FISICA" | "JURIDICA") => {

    const payload = {
      "tipoPersona": tipoPersona
    };
    
    const res = API.get("/negocio/condiciones-impositivas/opciones", { params: payload });
    return res.then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  };
}
