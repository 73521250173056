import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNationalitiesService } from '../../Services';

const createBaseAsyncMethod = (actionName: string, serviceMethod: any) => {
  return createAsyncThunk(
    actionName,
    async (_, { rejectWithValue }: any) => {
      const response = await serviceMethod();
      /* manipular response y checkeo de errores */
      
        if(response?.data && response?.status === 200){
          return response.data;
        }
        else {
          return rejectWithValue({ errorMessage: 'Error al obtener las Nacionalities'})
        }
      
    }
  );
}

export const getNationalitiesAsync = createBaseAsyncMethod('nacionalities/fetchGetNacionalities',  getNationalitiesService()?.getNationalities);


