import { PersonalDataFormProps } from "@experta/commons-v2.personal-data/dist/utils";
import { BeneficiaryForm } from "../../Parts/Forms/Beneficiary/utils";

const personalData: PersonalDataFormProps = {
  tipoPersona: "FISICA",
  condicionImpositiva: "CONSUMIDOR_FINAL",
  dni: "",
  cuit: "",
  socialReason: "",
  sex: "",
  name: "",
  lastname: "",
  email: "",
  areaCode: "",
  phone: "",
  localityID: "",
  street: "",
  streetNumber: "",
  floor: "",
  number: "",
  zipCode: "",
  nationality: "",
  birthday: "",
  takerActivity: "",
  stateID: "",
  locality: "",
};

const politicallyExposed = {
  politicallyExposed: false,
  reason: "",
};

const obligatedSubject = {
  obligatedSubject: false,
};

const beneficiaryData: BeneficiaryForm = {
  beneficiaryType: "TOMADOR",
  nonRepetitionClause: [],
  companyData: {
    cuit: "",
    businessName: "",
    errorsMessages: [],
  },
  beneficiaryInsures: [],
};

const paymentData = {
  paymentMethod: "CREDITO",
  cardNumber: "",
  expirationMonth: "",
  expirationYear: "",
  cardName: "",
  cardCompany: "",
  bankId: "",
  bankName: "",
  CBU: "",
};

export default {
  personalData,
  beneficiaryData,
  politicallyExposed,
  paymentData,
  obligatedSubject,
};
