import * as lang from "./lang.json"
import { Validations } from "@experta/commons-v2.layout";
import { PlansTypeValues } from "../../Store/PlansType/Slice";

import moment from "moment";
import { InsuredValues } from "../../Store/Insured/Slice";
import { ValidityTypesValues } from "../../Store/ValidityTypes/Slice";

/**
 * Interface used as form props.
 * @name InitialDataFormProps
 */
export interface InitialDataFormProps {
  commercialPlan: string,
  activity: string,
  quantity: string,
  period: string,
  dateFrom: string,
  dateTo: string,
  firstName: string,
  lastName: string,
  email: string,
  prefix: string,
  phone: string,
  captcha: string,
};

export type FormQuoteProps = {
  activities: Array<any>;
  onChange?: (data: any) => void;
  onSubmit?: () => void;
  plansType: Array<PlansTypeValues>;
  validitiesType: Array<ValidityTypesValues>;
  insuredOptions: Array<InsuredValues>;
  validateMethod: (localForm: InitialDataFormProps, lang: any) => Promise<{errorsMessages: Array<{field: string, message: string}>}>
}

/**
 * Quantity Values.
 * @name getQuantityValues
 */
export const getQuantityValues = (insuredOptions: InsuredValues[]) => {
  const quantity = [{name: lang.form.EmptyComboName, value: ""}];
  const insuredOptionsValues = [...insuredOptions];

  insuredOptionsValues.forEach((insuredOption) => {
    let option = {name: insuredOption.label, value: insuredOption.value};
    quantity.push(option);
  });

  return quantity;
};
/**
 * Period values.
 * @name getPeriodValues
 */
export const getPeriodValues = () => {
  return [
    {
      name: lang.form.EmptyComboName,
      value: "",
    },
    {
      name: "ANUAL",
      value: "ANUAL",
    },
    {
      name: "MENSUAL",
      value: "MENSUAL",
    },
    {
      name: "OTRO PERÍODO",
      value: "PERSONALIZADO",
    },
  ];
};

/**
 * Input Radio values.
 * @name getInputRadioParams
 */
export const getInputRadioParams = (formData: { commercialPlan: any }, plansType: PlansTypeValues[]) => {
  const plansTypeValues = plansType.map( (planType) => {
    return {
      "id": planType.value,
      "value": planType.value,
      "name": "commercialPlan",
      "labelDesktop": planType.label,
      "labelMobile": planType.label,
      "isChecked": formData.commercialPlan === planType.value,
    }
  });
  return {
    inputLabel: lang.form.commercialPlan.inputTitle,
    values: plansTypeValues,
  };
};

export const getMinimumDate = () => {
  const postMeridiem = moment().set({
    hour: 10,
    minute: 59,
    second: 59,
  });
  const isSameOrBeforeMeridiem = moment().isSameOrBefore(
    postMeridiem,
    "seconds"
  )
    ? 0
    : 1;
  const minimumDate = moment()
    .add(isSameOrBeforeMeridiem, "d")
    .format(lang.formatDate);
  return minimumDate;
};

/**
 * Initial state of personal data.
 * @name initialFormQuoteState
 */
export const getInitialFormQuoteState: (plansType: PlansTypeValues[]) => InitialDataFormProps = (plansType: PlansTypeValues[]) => {
  return {
    commercialPlan: "ENLATADO_LABORAL",
    activity: "",
    quantity: "",
    period: "",
    dateFrom: "",
    dateTo: "",
    firstName: "",
    lastName: "",
    email: "",
    prefix: "",
    phone: "",
    captcha: "",
  }
}