import { Validations } from "@experta/commons-v2.layout";
import { selectBeneficiaryFormData } from "../../../Store/Forms/Selectors";
import { useAppSelector } from "../../../Store/hooks";
import { InsuredValueProps } from "../Insureds/insureds";
import * as lang from "./lang.json";

/**
 * Interface used as props.
 * @name BeneficiaryProps
 */
export interface BeneficiaryProps {
    insureds: Array<InsuredValueProps>;
    onChangeFormData?: (formData: any) => void;
}

/**
 * Interface used as props.
 * @name BeneficiaryProps
 */
export interface BeneficiaryForm {
    beneficiaryType: string;
    nonRepetitionClause: Array<NonRepetitionClause>;
    companyData: CompanyData;
    beneficiaryInsures: Array<Array<BeneficiaryInsure>>;
}

/**
 * Interface used as non repetition clause form.
 * @name NonRepetitionClause
 */
export interface NonRepetitionClause {
    cuit: string,
    businessName: string,
    errorsMessages: Array<{field: string, message: string}>
}

/**
 * Interface used as company data form.
 * @name CompanyData
 */
export interface CompanyData {
    cuit: string,
    businessName: string,
    errorsMessages: Array<{field: string, message: string}>
}

/**
 * Interface used as beneficiary insures form.
 * @name BeneficiaryInsure
 */
export interface BeneficiaryInsure {
    name: string,
    lastname: string,
    dni: string,
    beneficiaryRelationship: string;
    benefitPercentage: string,
    errorsMessages: Array<{field: string, message: string}>
}

/**
 * Interface used as beneficiary insures form.
 * @name radioBeneficiaryTypeValues
 */
export const getRadioBeneficiaryTypeValues = (beneficiaryType: {value: string, label: string}[], formData: any) => {
    return beneficiaryType.map((item: {value: string, label: string}, index: number) => {
        return {
            id: item.value,
            value: item.value,
            name: "beneficiaryType",
            labelDesktop: item.label,
            labelMobile: item.label,
            isChecked: formData.beneficiaryType === beneficiaryType[index].value,
        }
    })

};


/**
 * Function witch check if beneficiaryForm is valid.
 * @name validateForm
 */

const validatePercents = (items: Array<BeneficiaryInsure>) =>{
    try {
        const sum = items.reduce((acum, current)=> acum + parseInt(current.benefitPercentage), 0);
        return sum === 100;
    } catch (error) {
        return false;
    }
}

export const validateForm = (currentForm: BeneficiaryForm): BeneficiaryForm => {
    if (currentForm.beneficiaryType === "FISICO") {
        currentForm.beneficiaryInsures.forEach((items: Array<BeneficiaryInsure>) => {
        const thereIsMoreThanOneInsured = items.length > 1;
        const isValidThePercentageSum = thereIsMoreThanOneInsured && validatePercents(items);
            items.forEach((item) => {
                item.errorsMessages = [];
                if (!(Validations.required(item.name))) {
                    item.errorsMessages.push({field: "name", message: lang.errors.required});
                }
                else if(!(Validations.fullName(item.name))){
                    item.errorsMessages.push({field: "name", message: lang.errors.invalid});
                }
                if (!(Validations.required(item.lastname))) {
                    item.errorsMessages.push({field: "lastname", message: lang.errors.required});
                }
                else if(!(Validations.fullName(item.lastname))){
                    item.errorsMessages.push({field: "lastname", message: lang.errors.invalid});
                }
                if (!(Validations.number(item.dni) && Validations.minLength(item.dni, 7) && Validations.maxLength(item.dni, 8))) {
                    item.errorsMessages.push({field: "dni", message: lang.errors.invalid});
                }
                if (!(Validations.required(item.beneficiaryRelationship))) {
                    item.errorsMessages.push({field: "beneficiaryRelationship", message: lang.errors.required});
                }
                if(thereIsMoreThanOneInsured){
                    if (!isValidThePercentageSum) {
                        item.errorsMessages.push({field: "benefitPercentage", message: lang.errors.invalid});
                    } else if (!(Validations.required(item.benefitPercentage))) {
                        item.errorsMessages.push({field: "benefitPercentage", message: lang.errors.required});
                    } else if (!(Validations.number(item.benefitPercentage)) || (parseInt(item.benefitPercentage) < 1 || parseInt(item.benefitPercentage) > 99)) {
                        item.errorsMessages.push({field: "benefitPercentage", message: lang.errors.invalid});
                    }
                }
                
            });
        });

    } else if (currentForm.beneficiaryType === "JURIDICO") {
        currentForm.companyData.errorsMessages = [];
        if (!(Validations.required(currentForm.companyData.businessName as string))) {
            currentForm.companyData.errorsMessages.push({field: "businessName", message: lang.errors.required});
        }
        else if (!(Validations.alphaNumeric(currentForm.companyData.businessName as string, true) && Validations.maxLength(currentForm.companyData.businessName as string, 60))) {
            currentForm.companyData.errorsMessages.push({field: "businessName", message: lang.errors.invalid});
        }
        if (!Validations.cuil(currentForm.companyData?.cuit as string)) {
            currentForm.companyData.errorsMessages.push({field: "cuit", message: lang.errors.invalid});
        }
    }
    currentForm.nonRepetitionClause.forEach((item: NonRepetitionClause) => {
        item.errorsMessages = [];
        if (!(Validations.required(item.businessName))) {
            item.errorsMessages.push({field: "businessName", message: lang.errors.required});
        }
        else if (!(Validations.alphaNumeric(item.businessName as string, true) && Validations.maxLength(item.businessName as string, 60))){
            item.errorsMessages.push({field: "businessName", message: lang.errors.invalid});
        }
        if (!Validations.cuil(item.cuit)) {
            item.errorsMessages.push({field: "cuit", message: lang.errors.invalid});
        }
    });

    return currentForm;
}