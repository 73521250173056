import { RootState } from "../store";
import { CardsBrands, CardsBrandsValues } from "./Slice";

const getCardsBrandsCombo = (values: Array<CardsBrandsValues>): Array<CardsBrands> => {
    let options:Array<{ value:string, name: string, }> = values.length > 1 ? [{ name: "", value: ""}] : [];
    let cardsBrands = [...values];
    
    cardsBrands.forEach((brand) => {
        let option = { name: brand.label, value: brand.value };
        options.push(option);
    });
    return options;
}

export const selectCardsBrands = (state: RootState) => state.cardsBrands.list;
export const selectCardsBrandsCombo = (state: RootState) => getCardsBrandsCombo(state.cardsBrands.list);
export const selectErrorMessageCardsBrands = (state: RootState) => state.cardsBrands.errorMessage;