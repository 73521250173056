import API from "./api";

export class BeneficiaryRelationshipService {

    public getBeneficiaryRelationshipTypes = async () => {
        const res = API.get('/negocio/relaciones-asegurado-beneficiario/opciones');
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err;
        })
    };

}