import { selectEnvVariables } from "../../Store/Variables/selectors";
import { useAppSelector } from "../../Store/hooks";

export const useTemplateUtils = () => {

    const variables = useAppSelector(selectEnvVariables) as any;

    /**
     * Params to use in header component.
     * @name headerParams
    */
    const headerParams = {
        backgroundColor: variables.REACT_APP_HEADER_BG_COLOR,
        logo: variables.REACT_APP_HEADER_LOGO
    }


    /**
     * Params to use in Footer component.
     * @name footerParams
     */
    const footerParams = {
        textFooter: variables.REACT_APP_FOOTER_TEXT,
        links: [
        { href: variables.REACT_APP_FOOTER_LINKS_1_LINK, description: variables.REACT_APP_FOOTER_LINKS_1_TEXT },
        { href: variables.REACT_APP_FOOTER_LINKS_2_LINK, description: variables.REACT_APP_FOOTER_LINKS_2_TEXT },
        { href: variables.REACT_APP_FOOTER_LINKS_3_LINK, description: variables.REACT_APP_FOOTER_LINKS_3_TEXT },
        { href: variables.REACT_APP_FOOTER_LINKS_4_LINK, description: variables.REACT_APP_FOOTER_LINKS_4_TEXT },
        { href: variables.REACT_APP_FOOTER_LINKS_5_LINK, description: variables.REACT_APP_FOOTER_LINKS_5_TEXT },
    ]};


    const headerLogoUrl = variables.REACT_APP_HEADER_LOGO_URL

    /**
    * Params to use in communication-channels component.
    * @name paramsCommunicationChannels
    */
    const paramsCommunicationChannels = {
        title: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_TITLE,
        mainColor: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_1_COLOR,
        mainIcon: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_1_ICON,
        data: [
            {
                color: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_2_COLOR,
                icon: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_2_ICON,
                link: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_2_LINK,
                text: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_2_TEXT || '',
            },
            {
                color: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_3_COLOR,
                icon: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_3_ICON,
                link: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_3_LINK,
                text: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_3_TEXT || '',
            },
            {
                color: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_4_COLOR,
                icon: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_4_ICON,
                link: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_4_LINK,
                text: variables.REACT_APP_HOME_COMMUNICATION_CHANNELS_4_TEXT || '',
            },
        ],
    };


    return {
        paramsCommunicationChannels,
        headerLogoUrl,
        footerParams,
        headerParams,
    };
};