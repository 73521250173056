import API from "./api";

interface PlansTypeParams {
  actions: string;
  brand: string;
}

export class PlansType {
  public getPlansType = async () => {
    const res = API.get("/negocio/tipo-planes/opciones");
    return res.then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  };
}
