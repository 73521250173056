import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTaxConditionsService } from '../../Services';

const createBaseAsyncMethod = (actionName: string, serviceMethod: any) => {
  return createAsyncThunk(
    actionName,
    async (data: any, { rejectWithValue }: any) => {
      const response = await serviceMethod(data);
      /* manipular response y checkeo de errores */
        if(response?.data && response?.status === 200){
          return response.data.map((condition: any) => {
            return { name: condition.label, value: condition.value, description: condition.description };
        });
        }
        else {
          return rejectWithValue({ errorMessage: 'Error al obtener las actividades'})
        }
      
    }
  );
}

export const getTaxConditionsAsync = createBaseAsyncMethod('taxConditions/fetchGetConditions',  getTaxConditionsService()?.getTaxConditions);
