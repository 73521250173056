import "./Beneficiary.css";
import * as lang from "./lang.json";
import { useEffect, useState } from "react";
import {Checkbox} from "@experta/commons-v2.checkbox";
import {Input} from "@experta/commons-v2.input";
import {Button} from "@experta/commons-v2.button";
import { NonRepetitionClause } from "./utils";

/**
 * Interface used as props.
 * @name NonRepetitionClauseFormProps
 */
export interface NonRepetitionClauseFormProps {
  onChange?: (data: any) => void;
}

/**
 * Main component.
 * @name NonRepetitionClauseForm
 * @param {NonRepetitionClauseFormProps}
 * @returns {<NonRepetitionClauseForm />}
 */

const NonRepetitionClauseForm = ({
  onChange = (data: any) => {},
}: NonRepetitionClauseFormProps) => {
  const [check, setCheck] = useState(false);
  const [formData, setFormData] = useState<Array<NonRepetitionClause>>([]);
  const handleChange = (e: any, index: number) => {
    const newFormData = [...formData];
    (newFormData as any)[index][e.target.name] = e.target.value;
    setFormData(newFormData);
  };

  const addCompany = () => {
    const newFormData = [...formData, { cuit: "", businessName: "", errorsMessages: [] }];
    setFormData(newFormData);
  };

  const deleteCompany = (index: number) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    setFormData(newFormData);
  };

  useEffect(() => {
    if (!check) {
      setFormData([]);
    } else {
      setFormData([{ cuit: "", businessName: "", errorsMessages: [] }]);
    }
  }, [check]);

  useEffect(() => {
    onChange(formData);
  }, [formData]);

  const hasError = (item :any, name: string) => {
    return item.errorsMessages.filter((el: any) => el.field === name).length > 0;
  };

  const getError = (item :any, name: string) => {
    if(hasError(item, name)){
      return item.errorsMessages.filter((el: any) => el.field === name)[0].message;
    }
    else {
      return undefined;
    }
  };
  
  return (
    <div className="beneficiary-container mt-3">
      <div className="d-inline-flex mt-3 mb-3">
        <Checkbox checked={check} onChange={() => setCheck(!check)} />
        <span className="ml-3">
          <label className="check-label">
            {lang.checkText}
            <b>{lang.checkTextBold}</b>
          </label>
          <p className="check-label-legal ml-0 mr-0 mt-2">
            {lang.checkTextLegal}
          </p>
        </span>
      </div>
      {check && formData.length > 0 && (
        <div className="row">
          <div className="col-md-5 col-12">
            <Input
              name="cuit"
              label={lang.cuit}
              onChange={(e: any) => handleChange(e, 0)}
              value={formData[0].cuit}
              type="text"
              error={hasError(formData[0], "cuit")}
              errorMessage={getError(formData[0], "cuit")}
            />
          </div>
          <div className="col-md-5 col-12">
            <Input
              name="businessName"
              label={lang.businessName}
              onChange={(e: any) => handleChange(e, 0)}
              value={formData[0].businessName}
              type="text"
              error={hasError(formData[0], "businessName")}
              errorMessage={getError(formData[0], "businessName")}
            />
          </div>
          <div className="col-md-2 col-12"></div>
          {formData.length > 0 &&
            formData.map((item: any, index: number) => {
              if (index === 0) {
                return <></>;
              } else {
                return (
                  <>
                    <div className="col-md-5 col-12">
                      <Input
                        name="cuit"
                        label={lang.cuit}
                        onChange={(e: any) => handleChange(e, index)}
                        value={item.cuit}
                        type="text"
                        error={hasError(item, "cuit")}
                        errorMessage={getError(item, "cuit")}
                      />
                    </div>
                    <div className="col-md-5 col-12">
                      <Input
                        name="businessName"
                        label={lang.businessName}
                        onChange={(e: any) => handleChange(e, index)}
                        value={item.businessName}
                        type="text"
                        error={hasError(item, "businessName")}
                        errorMessage={getError(item, "businessName")}
                      />
                    </div>
                    <div className="col-md-2 col-12 d-flex justify-content-md-start justify-content-center">
                      <Button className="col-md-12 col-10 button-secondary-outline-ap mb-3" onClick={() => deleteCompany(index)}>
                        {lang.deleteCompany}
                      </Button>
                    </div>
                  </>
                );
              }
            })}
          <div className="col-md-4 col-12 d-flex justify-content-md-start justify-content-center">
            <Button className="col-10 button-secondary-outline-ap" onClick={() => addCompany()}>{lang.addCompany}</Button>
          </div>
          <div className="col-md-8 col-12"></div>
        </div>
      )}
    </div>
  );
};

export default NonRepetitionClauseForm;
