import API from "./api";

export class InsuredService {
  public getInsured = async () => {
    const res = API.get("negocio/cantidad-asegurados/opciones");
    return res.then(async (resp) => {
      return resp;
    })
      .catch(err => {
        return err
      })
  };
}
