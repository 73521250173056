import { selectEnvVariables } from "../../Store/Variables/selectors";
import { useAppSelector } from "../../Store/hooks";


export const usePaymentUtils = () => {

    const variables = useAppSelector(selectEnvVariables) as any;

    const getPaymentMethodsImages = (): Array<string> => {
        return variables.REACT_APP_PAYMENT_PAYMENT_METHODS_IMAGES?.split(';') as Array<string>;
    }

    return {
        getPaymentMethodsImages
    }
}
