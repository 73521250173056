// Libraries
import { useHistory } from 'react-router-dom';

// Commons
import { PaymentResult } from '@experta/commons-v2.payment-result';

// Store
import { hideSpinner, showSpinner } from '../../Store/Spinner/Actions';
import {
  selectCanGoBackEmmitQuote,
  selectEmmitQuoteData,
  selectErrorMessageEmmitQuote,
} from '../../Store/EmmitQuote/Selectors';
import { selectDataFormData } from '../../Store/Data/Selectors';
import {
  selectQuoteHash,
  selectQuotePolicyNumber,
  selectSelectedPlan,
} from '../../Store/Quote/Selectors';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';

// Utils
import { useFinalUtils } from './utils';

// Languages
import * as lang from './lang.json';

// Styles
import './Final.css';

/**
 * Main component.
 * @name FinalPage
 * @param {FinalPageProps}
 * @returns {<FinalPage />}
 */

const FinalPage = ({}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { paymentResultParams } = useFinalUtils();

  const personalFormData = useAppSelector(selectDataFormData);
  const quoteHash = useAppSelector(selectQuoteHash);
  const formData = useAppSelector(selectDataFormData);
  const emmitQuoteData = useAppSelector(selectEmmitQuoteData);
  const selectedPlan = useAppSelector(selectSelectedPlan);
  const policyNumber = useAppSelector(selectQuotePolicyNumber);
  const canGoBack = useAppSelector(selectCanGoBackEmmitQuote);
  const paymentError = useAppSelector(selectErrorMessageEmmitQuote);

  const thereIsNonRepetitionClause =
    formData?.beneficiaryFormData.nonRepetitionClause.length > 0;

  const download = async (fileType: string) => {
    dispatch(showSpinner());
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ventas/${quoteHash}/${fileType}`
    );
    const responseBlob = await response.blob();
    const url = window.URL.createObjectURL(responseBlob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileType;
    anchor.click();
    dispatch(hideSpinner());
  };

  const formatSuccessMessage = () => {
    return lang.successText
      .replace(
        'SELECTED_PLAN_NAME',
        `<strong style="color: ${paymentResultParams.highlightColor}">${selectedPlan.nombrePlan}</strong>`
      )
      .replace(
        'POLICY_NUMBER',
        `<strong style="color: ${paymentResultParams.highlightColor}">${
          policyNumber || ''
        }</strong>`
      );
  };

  return (
    <div className='final-container'>
      {selectedPlan && (
        <PaymentResult
          titleSuccess={formatSuccessMessage()}
          titleError={paymentResultParams?.titleError}
          highlightColor={paymentResultParams?.highlightColor}
          textError={paymentError || paymentResultParams?.textError}
          isSuccessful={emmitQuoteData !== null}
          email={personalFormData?.personalFormData?.email}
        />
      )}
      {emmitQuoteData !== null && (
        <>
          <div className='row justify-content-md-center py-5'>
            {thereIsNonRepetitionClause && (
              <div className='col-md-4 py-1' style={{ textAlign: 'center' }}>
                <button
                  style={{ width: '16rem' }}
                  onClick={(e) => {
                    download('clausula-no-repeticion.pdf');
                  }}
                >
                  Descargar Cláusula de no Repetición
                </button>
              </div>
            )}
            <div className='col-md-4 py-1' style={{ textAlign: 'center' }}>
              <button
                style={{ width: '16rem' }}
                onClick={(e) => {
                  download('poliza.pdf');
                }}
              >
                Descargar Póliza
              </button>
            </div>
            <div className='col-md-4 py-1' style={{ textAlign: 'center' }}>
              <button
                style={{ width: '16rem' }}
                onClick={(e) => {
                  download('certificado-cobertura.pdf');
                }}
              >
                Descargar Certificado de Cobertura
              </button>
            </div>
          </div>
        </>
      )}
      {emmitQuoteData === null && canGoBack && (
        <div className='row justify-content-md-center py-5'>
          <div className='col-md-4 py-1' style={{ textAlign: 'center' }}>
            <button
              style={{ width: '16rem' }}
              onClick={() => history.push(`/pago?hashId=${quoteHash}`)}
            >
              {lang.backButton}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FinalPage;
