// React
import { useEffect, ReactNode } from 'react';

// Libraries
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

// Components
import { InitialDataFormProps } from '../../Components/FormQuote/utils';
import ErrorModal from '../ErrorModal/ErrorModal';
import ModalFormErrors from '../ModalFormErrors/ModalFormErrors';
import TokenModal from '../TokenModal/TokenModal';

// Commons
import { FloatingButton } from '@experta/commons-v2.floating-button';
import { Footer } from '@experta/commons-v2.footer';
import { Header } from '@experta/commons-v2.header';
import { Layout } from '@experta/commons-v2.layout';
import { Spinner } from '@experta/commons-v2.spinner';

// Style
import './Template.css';

// Store
import {
  generateInsuredsFormFields,
  saveInitialFormData,
} from '../../Store/Forms/Slice';
import { getEnvVariablesAsync } from '../../Store/Variables/actions';
import { getQuoteAsync } from '../../Store/Quote/Actions';
import { selectEnvVariables } from '../../Store/Variables/selectors';
import {
  selectShowSpinner,
  selectTextSpinner,
} from '../../Store/Spinner/Selectors';
import { selectQuoteHash } from '../../Store/Quote/Selectors';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';

// Utils
import { emitQuoteAsyncStep } from '../../Store/EmmitQuote/Slice';
import { getActivitiesAsync } from '../../Store/Activities/Actions';
import { getCardsBrandsAsync } from '../../Store/CardsBrands/Actions';
import { getGendersAsync } from '../../Store/Genders/Actions';
import { getNationalitiesAsync } from '../../Store/Nationalities/Actions';
import { getPaymentMethodsAsync } from '../../Store/PaymentMethods/Actions';
import { getPersonTypesAsync } from '../../Store/PersonTypes/Actions';
import { getQuoteService } from '../../Services';
import { getTakerActivitiesAsync } from '../../Store/TakerActivities/Actions';
import { getTaxConditionsAsync } from '../../Store/TaxConditions/Actions';
import { getValidityTypesAsync } from '../../Store/ValidityTypes/Actions';
import { saveHash, saveSelectedPlan } from '../../Store/Quote/Slice';
import { selectDataFormData } from '../../Store/Data/Selectors';
import { selectInitialFormData } from '../../Store/Forms/Selectors';
import { useTemplateUtils } from './utils';

/**
 * Interface used as props.
 * @name TemplateProps
 */
export interface TemplateProps {
  children: ReactNode;
}

/**
 * Main component.
 * @name Template
 * @param {TemplateProps}
 * @returns {<Template />}
 */

const Template = ({ children }: TemplateProps) => {
  const dispatch = useAppDispatch();
  const quoteHash = useAppSelector(selectQuoteHash);
  const showSpinner = useAppSelector(selectShowSpinner);
  const textSpinner = useAppSelector(selectTextSpinner);
  const variables = useAppSelector(selectEnvVariables);

  const {
    footerParams,
    headerLogoUrl,
    headerParams,
    paramsCommunicationChannels,
  } = useTemplateUtils();

  const history = useHistory();

  const { pathname } = useLocation();

  useEffect(() => {
    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let hash = params.has('hashId');
    let hash_data = params.get('hashId') as string;

    if (hash) {
      redirectTo(hash_data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!Object.values(variables)?.length) {
      dispatch(getEnvVariablesAsync());
    }
  }, [variables, dispatch]);

  const handler = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ): void => {
    const url = headerLogoUrl ?? '';
    if (url) {
      window.location.href = url;
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let hash = params.has('hashId');

    if (history.location.pathname !== '/' && !hash) {
      window.location.href = '/';
    }
  }, []);

  const redirectTo = async (hash: string) => {
    try {
      dispatch(saveHash(hash));
      const resp = await getQuoteService()?.getQuote(hash);

      if (resp.data) {
        let formData: InitialDataFormProps = {
          activity: resp.data.nomina.actividad,
          captcha: resp.data.captcha,
          commercialPlan: resp.data.nomina.descripcion,
          dateFrom: resp.data.vigencia.fechaInicio,
          dateTo: resp.data.vigencia.fechaFin,
          email: resp.data.contacto.email,
          firstName: resp.data.contacto.nombre,
          lastName: resp.data.contacto.apellido,
          period: resp.data.vigencia.tipoVigencia,
          phone: resp.data.contacto.telefono.numero,
          prefix: resp.data.contacto.telefono.prefijo,
          quantity: resp.data.nomina.capitas,
        };
        const selectedPlan = resp.data.planes.find(
          (plan: { seleccionado: boolean }) => plan.seleccionado === true
        );
        await Promise.all([
          dispatch(getQuoteAsync(hash)),
          dispatch(saveSelectedPlan(selectedPlan)),
          dispatch(getPaymentMethodsAsync()),
          dispatch(getCardsBrandsAsync()),
          dispatch(getActivitiesAsync('ENLATADO_LABORAL')),
          dispatch(getValidityTypesAsync()),
          dispatch(generateInsuredsFormFields(parseInt(formData.quantity))),
          dispatch(saveInitialFormData(formData)),
          dispatch(getNationalitiesAsync()),
          dispatch(getTakerActivitiesAsync()),
          dispatch(getGendersAsync()),
          dispatch(getPersonTypesAsync()),
          dispatch(getTaxConditionsAsync('FISICA')),
        ]).then(() => {
          switch (resp.data.estadoVenta) {
            case 'COTIZADA':
              history.push(`/planes?hashId=${hash}`);
              break;

            case 'A_EMITIR':
              history.push(`/datos?hashId=${hash}`);
              break;

            case 'EMITIDA':
              dispatch(emitQuoteAsyncStep());
              history.push(`/gracias?hashId=${hash}`);
              break;

            default:
              break;
          }
        });
      } else {
        throw new Error('invalid base64 format.');
      }
    } catch (error) {
      window.location.href = window.location.href.split('?')[0];
    }
  };

  const checkRoute = () => {
    return !(history.location.pathname !== '/' && !quoteHash);
  };

  return (
    <>
      {checkRoute() && (
        <div className='template-ap container-fluid p-0'>
          <Spinner show={showSpinner} text={textSpinner} />
          <Layout>
            <TokenModal />
            <ErrorModal />
            <ModalFormErrors />
            {Object.values(variables).length ? (
              <Header
                logoClickHandler={handler}
                backgroundColor={headerParams.backgroundColor}
                logoURL={headerParams.logo}
              />
            ) : (
              <></>
            )}
            {children}
            {Object.values(variables).length ? (
              <>
                <FloatingButton
                  communicationChannels={paramsCommunicationChannels.data}
                  alwaysShowTitle={false}
                  mainButtonStyles={{
                    backgroundColor: `${paramsCommunicationChannels.mainColor}`,
                  }}
                  icon={paramsCommunicationChannels.mainIcon}
                />
                <Footer
                  links={footerParams.links}
                  legalText={footerParams.textFooter}
                />
              </>
            ) : (
              <></>
            )}
          </Layout>
        </div>
      )}
    </>
  );
};

export default Template;
