import API from "./api";

export class CBUValidatorService {

    public validateCBU = async (cbu:string) => {
        const res = API.get(`/negocio/validaciones/pago/cbu/${cbu}`);
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err;
        })
    };

}