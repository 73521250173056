import { Validations } from "@experta/commons-v2.layout";
import moment from "moment";
import {
  getMinimumDate,
  InitialDataFormProps,
} from "../../Components/FormQuote/utils";
import { getPhoneValidator } from "../../Services";

export const formQuoteAsyncValidation = async (
  localFormData: InitialDataFormProps,
  lang: any
) => {
  let phonePrefixExists = false;
  let phoneNumberExists = false;

  const errorsMessages: Array<{ field: string; message: string }> = [];

  /**
   * sync validations
   */

  if (!Validations.required(localFormData.firstName)) {
    errorsMessages.push({
      field: "firstName",
      message: lang.default.errors.required,
    });
  } else if (
    !(
      Validations.fullName(localFormData.firstName) &&
      Validations.maxLength(localFormData.firstName, 29)
    )
  ) {
    errorsMessages.push({
      field: "firstName",
      message: lang.default.errors.invalid,
    });
  }

  if (!Validations.required(localFormData.lastName)) {
    errorsMessages.push({
      field: "lastName",
      message: lang.default.errors.required,
    });
  } else if (
    !(
      Validations.fullName(localFormData.lastName) &&
      Validations.maxLength(localFormData.lastName, 29)
    )
  ) {
    errorsMessages.push({
      field: "lastName",
      message: lang.default.errors.invalid,
    });
  }

  if (!Validations.required(localFormData.prefix)) {
    errorsMessages.push({ field: "prefix", message: lang.errors.required });
    phonePrefixExists = false;
  } else {
    phonePrefixExists = true;
  }

  if (!Validations.required(localFormData.phone)) {
    errorsMessages.push({
      field: "phone",
      message: lang.default.errors.required,
    });
    phoneNumberExists = false;
  } else {
    phoneNumberExists = true;
  }

  if (!Validations.required(localFormData.activity)) {
    errorsMessages.push({
      field: "activity",
      message: lang.default.errors.required,
    });
  }

  if (!Validations.required(localFormData.quantity)) {
    errorsMessages.push({
      field: "quantity",
      message: lang.default.errors.required,
    });
  }

  if (!Validations.required(localFormData.period)) {
    errorsMessages.push({
      field: "period",
      message: lang.default.errors.required,
    });
  }

  if (!Validations.required(localFormData.dateFrom)) {
    errorsMessages.push({
      field: "dateFrom",
      message: lang.default.errors.required,
    });
  } else if (
    moment(localFormData.dateFrom, lang.default.formatDate).isBefore(
      moment(getMinimumDate(), lang.default.formatDate)
    )
  ) {
    errorsMessages.push({
      field: "dateFrom",
      message: lang.default.errors.cantBeToday,
    });
  } else if (
    !moment(localFormData.dateFrom, lang.default.formatDate).isBefore(
      moment(localFormData.dateTo, lang.default.formatDate)
    )
  ) {
    errorsMessages.push({
      field: "dateFrom",
      message: lang.default.errors.posterior,
    });
  }

  if (!Validations.required(localFormData.dateTo)) {
    errorsMessages.push({
      field: "dateTo",
      message: lang.default.errors.required,
    });
  }

  if (!Validations.required(localFormData.captcha)) {
    errorsMessages.push({
      field: "captcha",
      message: lang.default.errors.required,
    });
  }

  /**
   * Async validations
   */

  if (phonePrefixExists && phoneNumberExists) {
    const phoneValidator = getPhoneValidator();

    try {
      const response = await phoneValidator?.validatePhone(
        localFormData.prefix,
        localFormData.phone
      );
      if (!response.data?.valido) {
        const field = response.data?.campo === "numero" ? "phone" : "prefix";

        if (field) {
          errorsMessages.push({
            field: field,
            message: response.data?.error || "Campo inválido",
          });
        } else {
          errorsMessages.push({
            field: "phone",
            message: "Campo inválido",
          });
          errorsMessages.push({
            field: "prefix",
            message: "Campo inválido",
          });
        }
      }
    } catch (error) {
      errorsMessages.push({ field: "phone", message: "Teléfono inválido" });
    }
  }

  return { errorsMessages };
};
