import API from "./api";

export class LocalitiesService {

    
    public getLocalities = async (zipCode:string) => {
        const payload = {
            "codigoPostal": zipCode
          };
        const res = API.get('/negocio/localidades/opciones', {params: payload});
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err
        })
    };

}


