import API from "./api";

export class BeneficiaryTypesService {
  public getBeneficiaryTypes = async () => {
    const res = API.get("/negocio/tipos-beneficiario/opciones");
    return res.then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  };
}
