import { useEffect, useState } from 'react';
import { useAppSelector } from '../../Store/hooks';
import { selectMessagesModalFormErrors } from '../../Store/ModalFormErrors/Selectors';
import './ModalFormErrors.css'
import * as lang from "./lang.json"

/**
 * Main component.
 * @name ModalFormErrors
 * @returns {<ModalFormErrors />}
 */


const ModalFormErrors = () => {
  const [showModal, setShowModal] = useState(false);
  const messages = useAppSelector(selectMessagesModalFormErrors);

  useEffect(() => {
    if (messages && messages.length > 0) {
      setShowModal(true);
    }
  }, [messages]);

  return (
    <>
      {showModal && (
        <div className="modal-form-error-container modal-bg">
          <div className="modal-content">
            <h1>{lang.title}</h1>
            <h4>{lang.subtitle}</h4>
            <div className="container">
              <div className="row">
                {
                  messages.map((message: string, idx: number) => {
                    return <div className="col-6 error-item">{message}</div>
                  })
                }
              </div>
            </div>
            <br />
            <br />
            <button onClick={() => setShowModal(false)}>{lang.button_text}</button>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalFormErrors;