import { createSlice } from '@reduxjs/toolkit';
import { getTaxConditionsAsync } from './Actions';

export interface TaxConditionsValues {
  value: string;
  name: string;
  description: string;
}

export interface TaxConditionsState {
  status: 'idle' | 'loading' | 'failed';
  taxConditions: Array<TaxConditionsValues>;
  errorMessage: string | undefined;
}

const initialState: TaxConditionsState = {
  status: 'idle',
  taxConditions: [],
  errorMessage: '',
};


export const taxConditionsSlice = createSlice({
  name: 'TaxConditions',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaxConditionsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTaxConditionsAsync.rejected, (state, action: any) => {
        state.status = 'failed';
        state.taxConditions = [];
        state.errorMessage = action.payload.errorMessage;
      })
      .addCase(getTaxConditionsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.taxConditions = action.payload;
        state.errorMessage = ''
      })
  },
});

export default taxConditionsSlice.reducer;
