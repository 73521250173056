import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLocalitiesService } from '../../Services';

export const getLocalitiesAsync = createAsyncThunk(
  'localities/fetchGetToken',
  async (cp:string) => {
    const data = await getLocalitiesService()?.getLocalities(cp)
    return (data as any)?.data;
  }
);


