import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQuoteService } from '../../Services';

const createBaseAsyncMethod = (actionName: string, serviceMethod: any) => {
  return createAsyncThunk(
    actionName,
    async (data: any, { rejectWithValue }: any) => {
      const response = await serviceMethod(data);
      const aceptedStatus = [200, 201, 202, 204];
      const theRequestWasSuccessful = aceptedStatus.some(aceptedCode => response.status === aceptedCode);
        if(theRequestWasSuccessful){
          return response.data;
        } 
        return rejectWithValue({
          refreshToken: false,
          errorMessage: response?.data?.message || "Error al emitir la cotización",
          canGoBack: response.status === 400 || false
        })
      }
  );
}

export const emmitQuoteAsync = createBaseAsyncMethod('emmitQuote/fetchEmmitQuote',  getQuoteService()?.emmitQuote);