import { selectEnvVariables } from "../../Store/Variables/selectors";
import { useAppSelector } from "../../Store/hooks";

export interface tycFormData {
    politicallyExposed: boolean,
    reason: string
}

export interface obligatedSubjectFormData {
    obligatedSubject: boolean,
}
  

export const useDataUtils = () => {

    const variables = useAppSelector(selectEnvVariables) as any;

    /**
     * Params to use in payment-result component.
     * @name paymentResultParams
     */
    const tycParams = {
        title: variables.REACT_APP_DATA_TYC_TITLE,
        description: variables.REACT_APP_DATA_TYC_DESCRIPTION,
        altDescription: variables.REACT_APP_DATA_TYC_ALT_DESCRIPTION,
        
    };

    const obligatedSubjectParams = {
        obligatedSubjectTitle: variables.REACT_APP_DATA_OS_TITLE,
        obligatedSubjectDescription: variables.REACT_APP_DATA_OS_DESCRIPTION,
    }

    return {
        tycParams,
        obligatedSubjectParams
    }


};