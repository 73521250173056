import { createSlice } from '@reduxjs/toolkit';
import {getCardsBrandsAsync} from './Actions';

export interface CardsBrandsValues {
  value: string; 
  label: string; 
}

export interface CardsBrands {
  value: string; 
  name: string; 
}

export interface CardsBrandsState {
  status: 'idle' | 'loading' | 'failed';
  list: Array<CardsBrandsValues>;
  errorMessage: string | undefined;
}

const initialState: CardsBrandsState = {
  status: 'idle',
  list: [],
  errorMessage: '',
};


export const cardsBrandsSlice = createSlice({
  name: 'CardsBrands',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCardsBrandsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCardsBrandsAsync.rejected, (state, action: any) => {
        state.status = 'failed';
        state.list = [];
        state.errorMessage = action.payload.errorMessage;
      })
      .addCase(getCardsBrandsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
        state.errorMessage = ''
      })
  },
});

export default cardsBrandsSlice.reducer;
