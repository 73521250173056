import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBeneficiaryTypesService } from '../../Services';

const createBaseAsyncMethod = (actionName: string, serviceMethod: any) => {
  return createAsyncThunk(
    actionName,
    async (_,{ rejectWithValue }: any) => {
      const response = await serviceMethod();
        if(response?.data?.length && response?.status === 200){
          return response.data;
        }
        else {
          return rejectWithValue({ errorMessage: 'Error al obtener los tipos de beneficiarios'})
        }
      
    }
  );
}

export const getBeneficiaryTypesAsync = createBaseAsyncMethod('beneficiaryTypes/fetch',  getBeneficiaryTypesService()?.getBeneficiaryTypes);
