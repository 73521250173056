import { createSlice } from '@reduxjs/toolkit';

export interface DataState {
  formData: any
}

const initialState: DataState = {
  formData: null,
};


export const dataSlice = createSlice({
  name: 'Data',
  initialState,
  reducers: {
    saveDataForm: (state, action) => {
      state.formData = action.payload;
    },
  },
  extraReducers: (builder) => {
  },
});

export const { saveDataForm } = dataSlice.actions;

export default dataSlice.reducer;
