import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import spinnerReducer from './Spinner/Slice';
import modalFormErrorsReducer from './ModalFormErrors/Slice';
import localitiesReducer from './Localities/Slice';
import activitiesReducer from './Activities/Slice';
import quoteReducer from './Quote/Slice';
import dataReducer from './Data/Slice';
import paymentReducer from './Payment/Slice';
import emmitQuoteReducer from './EmmitQuote/Slice';
import insuredReducer from './Insured/Slice';
import plansTypeReducer from './PlansType/Slice'; //coberturas
import beneficiaryTypesReducer from './BeneficiaryTypes/Slice'; //beneficiarios
import cardsBrandsReducer from './CardsBrands/Slice';
import paymentMethodsReducer from './PaymentMethods/Slice';
import nationalitiesReducer from './Nationalities/Slice';
import taxConditionsReducer from './TaxConditions/Slice';
import takerActivitiesReducer from './TakerActivities/Slice'
import personTypesReducer from './PersonTypes/Slice'
import validityTypesReducer from './ValidityTypes/Slice'
import gendersReducer from './Genders/Slice'
import formsReducer from './Forms/Slice';
import BeneficiaryRelationshipReducer from './BeneficiaryRelationshipTypes/slice';
import envVariablesReducer from './Variables/slice';

export const store = configureStore({
  reducer: {
    modalFormErrors: modalFormErrorsReducer,
    forms: formsReducer,
    spinner: spinnerReducer,
    localities: localitiesReducer,
    activities: activitiesReducer,
    quote: quoteReducer,
    data: dataReducer,
    payment: paymentReducer,
    emmitQuote: emmitQuoteReducer,
    insured: insuredReducer,
    plansType: plansTypeReducer,
    beneficiaryTypes: beneficiaryTypesReducer,
    cardsBrands: cardsBrandsReducer,
    paymentMethods: paymentMethodsReducer,
    nationalities: nationalitiesReducer,
    taxConditions: taxConditionsReducer,
    takerActivities: takerActivitiesReducer,
    personTypes: personTypesReducer,
    validityTypes: validityTypesReducer,
    genders: gendersReducer,
    beneficiaryRelationshipTypes: BeneficiaryRelationshipReducer,
    variables: envVariablesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
