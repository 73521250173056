import { useHistory } from "react-router-dom";
import { useAppSelector } from '../../Store/hooks';
import './TokenModal.css'
import * as lang from "./lang.json"
import { selectRefreshTokenEmmitQuote } from '../../Store/EmmitQuote/Selectors';
import { selectRefreshTokenPlans } from '../../Store/Quote/Selectors';

/**
 * Main component.
 * @name TokenModal
 * @returns {<TokenModal />}
 */


const TokenModal = () => {
  let history = useHistory();
  const refreshTokenEmmitQuote = useAppSelector(selectRefreshTokenEmmitQuote);
  const refreshTokenPlans = useAppSelector(selectRefreshTokenPlans);
  
  const handleClick = () => {
    if(history.location.pathname !== '/' ){
      history.push('/')
    } else {
      window.location.reload();
    }
  };

  return (
      <>
        {( refreshTokenEmmitQuote || refreshTokenPlans) && (
          <div className="token-modal modal-bg">
            <div className="modal-content">
              <h1>{ lang.title }</h1>
              <h3>{ lang.message }</h3>
              <br />
              <button onClick={handleClick}>{ lang.button_text }</button>
            </div>
          </div>
        )}
      </>
  );
};

export default TokenModal;