import API from './api';

export class EnvVariablesService {

  public getEnvVariables = async () => {
    try {
      const resp = await API.get('/negocio/variables');
      return resp;
    } catch (error) {
      return error;
    }
  }
}