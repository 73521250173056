import { createSlice } from '@reduxjs/toolkit';
import {getTakerActivitiesAsync} from './Actions';

export interface TakerActivitiesValues {
  value: string; 
  label: string; 
}

export interface TakerActivitiesState {
  status: 'idle' | 'loading' | 'failed';
  list: Array<TakerActivitiesValues>;
  errorMessage: string | undefined;
}

const initialState: TakerActivitiesState = {
  status: 'idle',
  list: [],
  errorMessage: '',
};


export const takerActivitiesSlice = createSlice({
  name: 'takerActivities',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTakerActivitiesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTakerActivitiesAsync.rejected, (state, action: any) => {
        state.status = 'failed';
        state.list = [];
        state.errorMessage = action.payload.errorMessage;
      })
      .addCase(getTakerActivitiesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
        state.errorMessage = ''
      })
  },
});

export default takerActivitiesSlice.reducer;
