import { RootState } from "../store";
import { PaymentMethodsValues } from "./Slice";

const getPaymentMethodsCombo = (values: Array<PaymentMethodsValues>) => {
    let options:Array<{ value:string, name: string }> = [{ name: "", value: ""}];
    let paymentMethods = [...values];
    
    paymentMethods.forEach((method) => {
        let option = { name: method.label, value: method.value };
        options.push(option);
    });
    return options;
}

export const selectPaymentMethods = (state: RootState) => state.paymentMethods.list;
export const selectPaymentMethodsCombo = (state: RootState) => getPaymentMethodsCombo(state.paymentMethods.list);
export const selectErrorMessagePaymentMethods = (state: RootState) => state.paymentMethods.errorMessage;