import API from "./api";

export class CardValidatorService {

    public validateCard = async (brand: string, number:string) => {
        const res = API.get(`/negocio/validaciones/pago/tarjeta-credito/empresa/${brand}/numero/${number}`);
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err;
        })
    };

}