import { RootState } from "../store";
import { NationalitiesValues } from "./Slice";

const getNationalitiesCombo = (values: Array<NationalitiesValues>) => {
    let options:Array<{ value:string, name: string, disabled?: boolean }> = [{ name: "", value: ""}];
    let nationalities = [...values];
    
    nationalities.forEach((nationalitie) => {
        let option = { name: nationalitie.label, value: nationalitie.value };
        options.push(option);
    });
    return options;
}

export const selectNationalities = (state: RootState) => state.nationalities.list;
export const selectNationalitiesCombo = (state: RootState) => getNationalitiesCombo(state.nationalities.list);
export const selectErrorMessageNationalities = (state: RootState) => state.nationalities.errorMessage;