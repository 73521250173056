import { forwardRef, useImperativeHandle, useReducer, useState } from "react";
import {Button} from "@experta/commons-v2.button";
import {Input} from "@experta/commons-v2.input";
import * as lang from "./lang.json";
import { Validations } from "@experta/commons-v2.layout";

interface PaymentInsuredApplyProps {
  formValues: any;
  onSubmit?: (data: any) => void;
}

const PaymentInsuredApply = ({ formValues, onSubmit = (data: any) => { } }: PaymentInsuredApplyProps, ref: any) => {
  const formReducer = (state: any, event: any) => {
    const newState = {
      ...state,
      [event.name]: event.value,
    }
    return newState;
  };
  const [formData, setFormData] = useReducer(formReducer, formValues);
  const [formDataErrors, setFormDataErrors] = useState<Array<{field: string, message: string}>>([]);

  const handleChange = (e: any) => {
    setFormData({
      name: e.target.name,
      value: e.target.value,
    });
  };

  const handleSubmit = (e: any) => {
    onSubmit(formData);
  };


  useImperativeHandle(ref, () => ({
    validateForm: () => {
      let errorsMessages = [];
      if (formData.tipoPersona === 'FISICA') {
        if (!(Validations.required(formData.name))) {
          errorsMessages.push({field: "name", message: lang.errors.required});
        }
        else if (!(Validations.fullName(formData.name))) {
          errorsMessages.push({field: "name", message: lang.errors.invalid});
        }
        if (!(Validations.required(formData.lastname))) {
          errorsMessages.push({field: "lastname", message: lang.errors.required});
        }
        else if (!(Validations.fullName(formData.lastname))) {
          errorsMessages.push({field: "lastname", message: lang.errors.invalid});
        }
        if (!(Validations.number(formData.dni) && Validations.minLength(formData.dni, 7) && Validations.maxLength(formData.dni, 8))) {
          errorsMessages.push({field: "dni", message: lang.errors.invalid});
        }
      } else {
        if (!(Validations.required(formData.businessName))) {
          errorsMessages.push({field: "businessName", message: lang.errors.required});
        }
        else if (!(Validations.fullName(formData.businessName))) {
          errorsMessages.push({field: "businessName", message: lang.errors.invalid});
        }
        if (!Validations.cuil(formData.cuit)) {
          errorsMessages.push({field: "cuit", message: lang.errors.invalid});
        }
      }

      setFormDataErrors(errorsMessages);

      return errorsMessages.length === 0;
    }
  }), [formData]);

  const hasError = (name: string) => {
    return formDataErrors.filter(el => el.field === name).length > 0;
  };

  const getError = (name: string) => {
    if(hasError(name)){
      return formDataErrors.filter(el => el.field === name)[0].message;
    }
    else {
      return undefined;
    }
  };


  return (
    <div className="row">
      {formData.tipoPersona === 'FISICA' ? (
        <>
          <div className="col">
            <Input
              name="dni"
              label={lang.form.dni}
              value={formData.dni}
              onChange={handleChange}
              type="number"
              error={hasError("dni")}
              errorMessage={getError("dni")}
            />
          </div>
          <div className="col">
            <Input
              name="name"
              label={lang.form.firstName}
              onChange={handleChange}
              value={formData.name}
              error={hasError("name")}
              errorMessage={getError("name")}
            />
          </div>
          <div className="col">
            <Input
              name="lastname"
              label={lang.form.lastName}
              onChange={handleChange}
              value={formData.lastname}
              error={hasError("lastname")}
              errorMessage={getError("lastname")}
            />
          </div>
        </>
      ) : (
        <>
          <div className="col">
            <Input
              name="cuit"
              label={lang.form.cuit}
              value={formData.cuit}
              onChange={handleChange}
              type="number"
              error={hasError("cuit")}
              errorMessage={getError("cuit")}
            />
          </div>
          <div className="col">
            <Input
              name="businessName"
              label={lang.form.businessName}
              onChange={handleChange}
              value={formData.businessName}
              error={hasError("businessName")}
              errorMessage={getError("businessName")}
            />
          </div>
        </>
      )}
      <div className="col">
        <Button className="button-aceptar button-primary-solid-ap" onClick={handleSubmit}>
          {lang.form.accept}
        </Button>
      </div>
    </div>
  );
};

export default forwardRef(PaymentInsuredApply);
