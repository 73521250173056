import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActivitiesService } from '../../Services';

const createBaseAsyncMethod = (actionName: string, serviceMethod: any) => {
  return createAsyncThunk(
    actionName,
    async (data: any, { rejectWithValue }: any) => {
      const response = await serviceMethod(data);
      /* manipular response y checkeo de errores */
        if(response?.data && response?.status === 200){
          return response.data;
        }
        else {
          return rejectWithValue({ errorMessage: 'Error al obtener las actividades'})
        }
      
    }
  );
}

export const getActivitiesAsync = createBaseAsyncMethod('activities/fetchGetActivities',  getActivitiesService()?.getActivities);
