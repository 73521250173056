import { forwardRef, useCallback, useEffect, useImperativeHandle, useReducer, useState } from "react";

import "./FormQuote.css";
import * as lang from "./lang.json";
import {
  FormQuoteProps,
  getInitialFormQuoteState,
  getInputRadioParams,
  getMinimumDate,
  getPeriodValues,
  getQuantityValues,
} from "./utils";
import moment from "moment";

import {Input} from "@experta/commons-v2.input";
import {Button} from "@experta/commons-v2.button";
import {Select} from "@experta/commons-v2.select";
import {InputRadio} from "@experta/commons-v2.input-radio";
import {Recaptcha} from "@experta/commons-v2.recaptcha";

import { PlansTypeValues } from "../../Store/PlansType/Slice";
import { getActivitiesAsync } from "../../Store/Activities/Actions";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { hideSpinner, showSpinner } from "../../Store/Spinner/Actions";
import { InsuredValues } from "../../Store/Insured/Slice";
import { selectEnvVariables } from "../../Store/Variables/selectors";

const FormQuote = ({
  activities,
  onChange = (data: any) => {},
  onSubmit = () => {},
  validateMethod,
  plansType,
  validitiesType,
  insuredOptions
}: FormQuoteProps, ref: any) => {

  const variables = useAppSelector(selectEnvVariables) as any;


  const sitekey= variables.REACT_APP_RECAPTCHA_SITEKEY as string;
  const formReducer = (state: any, event: any) => {
    const newState = {
      ...state,
      [event.name]: event.value,
    };
    onChange(newState);
    return newState;
  };
  const dispatch = useAppDispatch();

 const [recaptchaResponse, setRecaptchaResponse] = useState<any>("");
  const [formDataErrors, setFormDataErrors] = useState<Array<{field: string, message: string}>>([]);
  const [inputDateFromDisabled, setInputDateFromDisabled] =
    useState<boolean>(true);
  const [inputDateToDisabled, setInputDateToDisabled] = useState<boolean>(true);

  const [formData, setFormData] = useReducer(formReducer, getInitialFormQuoteState(plansType));

  const handlerRecaptchaResponse = useCallback((recaptcha: any) => {
    setRecaptchaResponse(recaptcha);
    setFormData({
      name: "captcha",
      value: recaptcha,
    });
  }, []);

  const cleanFormDates = () => {
    setFormData({
      name: "dateFrom",
      value: "",
    });
    setFormData({
      name: "dateTo",
      value: "",
    });
  };

  const getMaximumDate = () => {
    const maximumDate = moment(getMinimumDate()).add(1, "M").format(lang.formatDate);
    return maximumDate;
  };

  const getMinimumDateOthers = () => {
    const minimumDate =moment(formData.dateFrom, lang.formatDate).add(1, "d").format(lang.formatDate);
    return minimumDate;
  };

  const handleChange = async (e: any) => {
    if (e.target.name === "period") {
      switch (e.target.value) {
        case "ANUAL":
          setInputDateFromDisabled(false);
          setInputDateToDisabled(true);
          break;
        case "MENSUAL":
          setInputDateFromDisabled(false);
          setInputDateToDisabled(true);
          break;
        case "PERSONALIZADO":
          setInputDateFromDisabled(false);
          setInputDateToDisabled(false);
          setFormData({
            name: "dateTo",
            value: "",
          });
          break;
        default:
          setInputDateFromDisabled(true);
          setInputDateToDisabled(true);
          cleanFormDates();
          break;
      }
    }

    if (e.target.name === "dateFrom") {
      const dateSelected = moment(e.target.value, lang.formatDate);
      if (formData.period === "ANUAL") {
        const nextYear = dateSelected.add(1, "y").subtract(1, "d").format(lang.formatDate);
        setFormData({
          name: e.target.name,
          value: e.target.value,
        });
        setFormData({
          name: "dateTo",
          value: nextYear,
        });
      } else if (formData.period === "MENSUAL") {
        const nextMonth = dateSelected.add(1, "M").subtract(1, "d").format(lang.formatDate);
        setFormData({
          name: e.target.name,
          value: e.target.value,
        });
        setFormData({
          name: "dateTo",
          value: nextMonth,
        });
      }
    }

    if(e.target.name === 'commercialPlan') {
      setFormData({
        name: e.target.name,
        value: e.target.value,
      });
      await dispatch(showSpinner());
      await dispatch(getActivitiesAsync(e.target.value));
      await dispatch(hideSpinner());
    }
    setFormData({
      name: e.target.name,
      value: e.target.value,
    });

  };

  useEffect(() => {
    if (formData.period === "ANUAL") {
      const nextYear =
        formData.dateFrom &&
        moment(formData.dateFrom, lang.formatDate).add(1, "y").format(lang.formatDate);
      setFormData({
        name: "dateTo",
        value: nextYear,
      });
    } else if (formData.period === "MENSUAL") {
      const nextMonth =
        formData.dateFrom &&
        moment(formData.dateFrom, lang.formatDate).add(1, "M").format(lang.formatDate);
      setFormData({
        name: "dateTo",
        value: nextMonth,
      });
    }
  }, [formData.period]);

  useEffect(() => {
   
    if (formData.period === "PERSONALIZADO") {
      setFormData({
        name: "dateTo",
        value: "",
      });
    } 
  }, [formData.dateFrom]);

  useImperativeHandle(ref, () => ({
      validateForm: async () => {
      let result = await validateMethod(formData, lang);
      setFormDataErrors([...result.errorsMessages]);
      return result.errorsMessages;
    }
  }), [formData]);

  const hasError = (name: string) => {
    return formDataErrors.filter(el => el.field === name).length > 0;
  };

  const getError = (name: string) => {
    if(hasError(name)){
      return formDataErrors.filter(el => el.field === name)[0].message;
    }
    else {
      return undefined;
    }
  };

  return (
    <div className="form-quote-container p-xl-5 px-lg-4">
      <span className="form-quote-title">{lang.form.title}</span>
      <form className="form-quote">
        <div className="row">
          <div className="col-12">
            <InputRadio
              inputLabel={getInputRadioParams(formData, plansType).inputLabel}
              values={getInputRadioParams(formData, plansType).values}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Select
              name="activity"
              options={activities}
              label={lang.form.activity}
              onChange={handleChange}
              value={formData.activity}
              error={hasError("activity")}
              errorMessage={getError("activity")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Select
              id="ap-cantidad"
              name="quantity"
              options={getQuantityValues(insuredOptions)}
              label={lang.form.quantity}
              onChange={handleChange}
              value={formData.quantity}
              error={hasError("quantity")}
              errorMessage={getError("quantity")}
            />
          </div>
          <div className="col-md-6">
            <Select
              id=""
              name="period"
              options={validitiesType}
              label={lang.form.period}
              onChange={handleChange}
              value={formData.period}
              error={hasError("period")}
              errorMessage={getError("period")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Input
              name="dateFrom"
              label={lang.form.dateFrom}
              onChange={handleChange}
              value={formData.dateFrom}
              disabled={inputDateFromDisabled}
              type="date"
              min={getMinimumDate()}
              max={inputDateFromDisabled ? undefined : getMaximumDate()}
              error={hasError("dateFrom")}
              errorMessage={getError("dateFrom")}
            />
          </div>
          <div className="col-md-6">    
            <Input
              name="dateTo"
              label={lang.form.dateTo}
              onChange={handleChange}
              value={formData.dateTo}
              disabled={inputDateToDisabled}
              type="date"
              min={formData.period === 'PERSONALIZADO' ? getMinimumDateOthers() : getMinimumDate()}
              max={moment(getMinimumDate()).add(1, "y").format(lang.formatDate)}
              error={hasError("dateTo")}
              errorMessage={getError("dateTo")}
            />
          </div>
        </div>
        <p className="form-subtitle">{lang.form.subtitle}</p>
        <div className="row">
          <div className="col-md-6">
            <Input
              name="firstName"
              label={lang.form.firstName}
              onChange={handleChange}
              value={formData.firstName}
              error={hasError("firstName")}
              errorMessage={getError("firstName")}
            />
          </div>
          <div className="col-md-6">
            <Input
              name="lastName"
              label={lang.form.lastName}
              onChange={handleChange}
              value={formData.lastName}
              error={hasError("lastName")}
              errorMessage={getError("lastName")}
            /> 
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Input
              name="email"
              label={lang.form.email}
              onChange={handleChange}
              value={formData.email}
              error={hasError("email")}
              errorMessage={getError("email")}
              placeholder={lang.form.emailPlaceholder}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Input
              name="prefix"
              label={lang.form.prefix}
              onChange={handleChange}
              value={formData.prefix}
              type="number"
              maxLength={4}
              error={hasError("prefix")}
              errorMessage={getError("prefix")}
            />
          </div>
          <div className="col-md-8">
            <Input
              name="phone"
              label={lang.form.phone}
              onChange={handleChange}
              value={formData.phone}
              type="number"
              error={hasError("phone")}
              errorMessage={getError("phone")}
            />
          </div>
        </div>
        {
          sitekey && (
            <Recaptcha
              sitekey={sitekey}
              handlerRecaptchaResponse={handlerRecaptchaResponse}
            />
          )
        }
        <div className="row justify-content-center">
          <Button
            onClick={onSubmit}
            className="col-8 form-quote-button"
            type="button"
            style={ recaptchaResponse === "" ? ({"cursor": "not-allowed",
            //style={ formData.capcha === "" ? ({"cursor": "not-allowed",
              "filter": "alpha(opacity=65)",
              "WebkitBoxShadow": "none",
              "boxShadow": "none",
              "opacity": ".65"}) : ({})}
            
          >
            {lang.buttonText}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default forwardRef(FormQuote);

