// React
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

// Libraries
import { useHistory } from 'react-router-dom';

// Commons
import { PlanCardProps } from '@experta/commons-v2.plans/dist/components/plan-card/plan-card';
import { Button } from '@experta/commons-v2.button';
import { InfoHeader } from '@experta/commons-v2.info-header';
import { Plans } from '@experta/commons-v2.plans';
import { StepsPayment } from '@experta/commons-v2.steps-payment';

// Parts
import InfoHeaderForm from '../../Parts/Forms/InfoHeader/InfoHeaderForm';

// Store
import {
  generateInsuredsFormFields,
  saveInitialFormData,
} from '../../Store/Forms/Slice';
import { getGendersAsync } from '../../Store/Genders/Actions';
import { getNationalitiesAsync } from '../../Store/Nationalities/Actions';
import { getPersonTypesAsync } from '../../Store/PersonTypes/Actions';
import {
  getQuoteAsync,
  selectedPlanAsync,
  updateQuoteAsync,
} from '../../Store/Quote/Actions';
import { getTakerActivitiesAsync } from '../../Store/TakerActivities/Actions';
import { getTaxConditionsAsync } from '../../Store/TaxConditions/Actions';
import { hideSpinner, showSpinner } from '../../Store/Spinner/Actions';
import {
  saveSelectedCoverages,
  saveSelectedPlan,
} from '../../Store/Quote/Slice';
import { selectActivitiesCombo } from '../../Store/Activities/Selectors';
import { selectEnvVariables } from '../../Store/Variables/selectors';
import { selectInitialFormData } from '../../Store/Forms/Selectors';
import {
  selectQuote,
  selectQuoteHash,
  selectQuotePlans,
  selectSelectedPlan,
} from '../../Store/Quote/Selectors';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';

// Utils
import { getTextFormatPrice, usePlansUtils } from './utils';

// Assets
import Icon1 from '../../Assets/Icons/ICONO_COTIZACION.svg';
import Icon2 from '../../Assets/Icons/ICONO_PLANES.svg';
import Icon3 from '../../Assets/Icons/ICONO_DATOS.svg';
import Icon4 from '../../Assets/Icons/ICONO_PAGO.svg';

// Languages
import * as lang from './lang.json';

// Styles
import './Plans.css';

/**
 * Interface used as props.
 * @name PlansPageProps
 */
export interface PlansPageProps {}

/**
 * Main component.
 * @name PlansPage
 * @param {PlansPageProps}
 * @returns {<PlansPage />}
 */

/**
 * @name CoverageProps
 */
export type CoverageProps = {
  codigoCobertura: string;
  descripcion: string;
  montoAsegurado?: number;
  nombreCobertura: string;
};

const PlansPage = ({}: PlansPageProps) => {
  let history = useHistory();
  const dispatch = useAppDispatch();

  const { paramsPlans } = usePlansUtils();
  const variables = useAppSelector(selectEnvVariables) as any;
  const [showInputs, setShowInputs] = useState<boolean>(false);
  const activitiesCombo = useAppSelector(selectActivitiesCombo);
  const formData = useAppSelector(selectInitialFormData);
  const vigencia = useAppSelector(selectQuote);
  const selectedPlanName = useAppSelector(selectSelectedPlan);
  const plans = useAppSelector(selectQuotePlans);
  const quoteHash = useAppSelector(selectQuoteHash);
  const [selectedPlan, setSelectedPlan] = useState<number>(0);
  const [selectedPlanWasDefined, setSelectedPlanWasDefined] = useState(false);

  useEffect(() => {
    setSelectedPlanWasDefined(false);
    onChangePlans(selectedPlan);
  }, [plans]);

  const planCardColor = variables.REACT_APP_ACCENT_COLOR;
  const currentStep = 1;
  const stepsStyle = {
    color: 'white',
    bgColor: 'rgba(23, 160, 213, 0.7)',
    bgColorActive: 'rgba(23, 160, 213, 1)',
  };
  const valuesInfoHeader = [
    {
      title: 'Actividad',
      value: activitiesCombo?.filter(
        (activity) => activity?.value === formData?.activity
      )[0]?.name,
    },
    { title: 'Personas Aseguradas', value: formData?.quantity },
    { title: 'Desde', value: formData?.dateFrom },
    { title: 'Hasta', value: formData?.dateTo },
  ];
  const backgroundColorInfoHeader = 'rgb(238,238,238)';
  const stepsPayment = [
    { title: lang.step1Title, icon: Icon1 },
    { title: lang.step2Title, icon: Icon2 },
    { title: lang.step3Title, icon: Icon3 },
    { title: lang.step4Title, icon: Icon4 },
  ];

  const formatPlans = () => {
    const resPlans: PlanCardProps[] = [];
    plans?.forEach((plan: any, index: number) => {
      if (!selectedPlanWasDefined) {
        if (plan.recomendado) {
          setSelectedPlanWasDefined(true);
          setSelectedPlan(index);
        }
      }
      const coveragesPlan = plan.coberturas.map((coverage: CoverageProps) => {
        return {
          name: coverage.nombreCobertura,
          value: coverage.montoAsegurado,
          id: coverage.codigoCobertura,
        };
      });

      const newPlan = {
        name: plan.nombrePlan,
        price: getTextFormatPrice({
          validity: vigencia?.vigencia?.tipoVigencia,
          plan: plan,
        }),
        coverages: coveragesPlan,
        id: index,
        assists: [],
        headerText: plan?.nombrePlan,
        recomended: plan?.recomendado,
        minAge: `${plan.edadMinima} años`,
        maxAge: `${plan.edadMaxima} años`,
      };
      resPlans.push(newPlan);
    });
    if (!selectedPlanWasDefined) {
      setSelectedPlan(Math.floor(plans?.length / 2));
      setSelectedPlanWasDefined(true);
    }
    return resPlans;
  };

  const onSubmitInfoHeader = async (data: any) => {
    dispatch(showSpinner());
    const haveBeenChanges = JSON.stringify(formData) !== JSON.stringify(data);
    if (haveBeenChanges) {
      dispatch(generateInsuredsFormFields(data.quantity));
      dispatch(saveInitialFormData({ ...data }));
      await dispatch(
        updateQuoteAsync({ formData: data, quoteHash: quoteHash })
      );
      await dispatch(getQuoteAsync(quoteHash));
    }
    setShowInputs(!showInputs);
    dispatch(hideSpinner());
  };

  const onChangePlans = async (index: number) => {
    if (plans && plans.length > index) {
      const planSelected = plans[index];
      await dispatch(saveSelectedPlan(planSelected));
      await dispatch(saveSelectedCoverages(planSelected.coberturas));
      setSelectedPlan(index);
    }
  };

  const goToNextPage = async () => {
    dispatch(showSpinner());
    await dispatch(
      selectedPlanAsync({ selectedPlan: selectedPlanName.idPlan, quoteHash })
    );
    await dispatch(getNationalitiesAsync());
    await dispatch(getTakerActivitiesAsync());
    await dispatch(getGendersAsync());
    await dispatch(getPersonTypesAsync());
    await dispatch(getTaxConditionsAsync('FISICA'));
    dispatch(hideSpinner());
    history.push(`/datos?hashId=${quoteHash}`);
  };

  return (
    <>
      <div
        className='info-header-wrapper'
        style={{ backgroundColor: backgroundColorInfoHeader }}
      >
        <InfoHeader
          backgroundColor={backgroundColorInfoHeader}
          title={lang.infoHeaderTitle}
          insuredAssets={valuesInfoHeader}
          showInputs={showInputs}
          setShowInputs={setShowInputs}
        >
          <InfoHeaderForm
            activities={activitiesCombo}
            formValues={formData}
            onSubmit={(data) => {
              onSubmitInfoHeader(data);
            }}
          />
        </InfoHeader>
      </div>
      <StepsPayment
        currentStep={currentStep}
        style={stepsStyle}
        steps={stepsPayment}
      />
      <div className='p-3 p-lg-5 mb-3 bg-page'>
        {plans && (
          <Plans
            plans={formatPlans()}
            selectedPlan={selectedPlan}
            onChange={(id: number) => onChangePlans(id)}
            color={planCardColor}
          />
        )}
        <div className='row justify-content-center mt-3 mb-3'>
          <p className='col-md-8 col-10 mb-3 legaltxt'>
            {paramsPlans.legalText}
          </p>
          <div className='col-md-4 col-8 d-flex justify-content-md-end'>
            <Button
              className={'button-next button-primary-solid-ap'}
              onClick={goToNextPage}
            >
              {lang.buttonTitle}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlansPage;
