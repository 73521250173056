import { RootState } from "../store";
import { ActivitiesValues } from "./Slice";

const getActivitiesCombo = (values: Array<ActivitiesValues>) => {
    let options:Array<{ value:string|number, name: string, disabled?: boolean }> = [{ name: "", value: ""}];
    let activities = [...values];

    activities.forEach((activity) => {
        let option = { name: activity.label, value: activity.value };
        options.push(option);
    });
    return options;
}

export const selectActivities = (state: RootState) => state.activities.activities;
export const selectActivitiesCombo = (state: RootState) => getActivitiesCombo(state.activities.activities);
export const selectErrorMessageActivities = (state: RootState) => state.activities.errorMessage;