import { useReducer, useState } from "react";
import {Button} from "@experta/commons-v2.button";
import {Select} from "@experta/commons-v2.select";
import {Input} from "@experta/commons-v2.input";
import * as lang from "./lang.json";
import { getQuantityValues, validateForm } from "./utils";
import moment from "moment";

interface InfoHeaderFormProps {
  activities: Array<any>;
  formValues: any;
  onSubmit?: (data: any) => void;
}

const InfoHeaderForm = ({ activities, formValues, onSubmit = (data: any) => {} }: InfoHeaderFormProps) => {
  const formReducer = (state: any, event: any) => {
    const newState = {
      ...state,
      [event.name]: event.value,
    }
    return newState;
  };

  const [formData, setFormData] = useReducer(formReducer, formValues);
  const [formDataErrors, setFormDataErrors] = useState<Array<{field: string, message: string}>>([]);

  const handleChange = (e: any) => {
    if (e.target.name === "dateFrom") {
      const dateSelected = moment(e.target.value, lang.formatDate);
      if (formData.period === "ANUAL") {
        const nextYear = dateSelected.add(1, "y").subtract(1, "d").format(lang.formatDate);
        setFormData({
          name: e.target.name,
          value: e.target.value,
        });
        setFormData({
          name: "dateTo",
          value: nextYear,
        });
      } else if (formData.period === "MENSUAL") {
        const nextMonth = dateSelected.add(1, "M").subtract(1, "d").format(lang.formatDate);
        setFormData({
          name: e.target.name,
          value: e.target.value,
        });
        setFormData({
          name: "dateTo",
          value: nextMonth,
        });
      } else if (formData.period === "PERSONALIZADO") {
        setFormData({
          name: e.target.name,
          value: e.target.value,
        });
      }
    }
    setFormData({
      name: e.target.name,
      value: e.target.value,
    });
  };

  const getMinimumDateOthers = () => {
    const minimumDate =moment(formData.dateFrom, lang.formatDate).add(1, "d").format(lang.formatDate);
    return minimumDate;
  };

  const getMinimumDate = () => {
    const postMeridiem = moment().set({
      hour: 11,
      minute: 59,
      second: 59,
    });
    const isSameOrBeforeMeridiem = moment().isSameOrBefore(
      postMeridiem,
      "seconds"
    )
      ? 0
      : 1;
    const minimumDate = moment()
      .add(isSameOrBeforeMeridiem, "d")
      .format(lang.formatDate);
    return minimumDate;
  };

  const getMaximumDate = () => {
    const maximumDate = moment(getMinimumDate()).add(3, "M").format(lang.formatDate);
    return maximumDate;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let errorMessages = validateForm(formData);
    setFormDataErrors([...errorMessages.errorsMessages]);

    if(errorMessages.errorsMessages.length === 0){
      onSubmit(formData);
    }
  };

  const hasError = (name: string) => {
    return formDataErrors.filter(el => el.field === name).length > 0;
  };

  const getError = (name: string) => {
    if(hasError(name)){
      return formDataErrors.filter(el => el.field === name)[0].message;
    }
    else {
      return undefined;
    }
  };

  return (
    <div className="row">
        {activities && (
          <div className="col">
            <Select
              name="activity"
              options={activities}
              label={lang.form.activity}
              onChange={handleChange}
              value={formData.activity}
              error={hasError("activity")}
              errorMessage={getError("activity")}
            />
          </div>
        )}
        <div className="col">
        <Input
          name="dateFrom"
          label={lang.form.dateFrom}
          onChange={handleChange}
          value={formData.dateFrom}
          type="date"
          min={getMinimumDate()}
          max={getMaximumDate()}
          error={hasError("dateFrom")}
          errorMessage={getError("dateFrom")}
        />
        </div>
        <div className="col">
        <Input
          name="dateTo"
          label={lang.form.dateTo}
          onChange={handleChange}
          value={formData.dateTo}
          type="date"
          min={formData.period === 'PERSONALIZADO' ? getMinimumDateOthers() : getMinimumDate()}
          max={moment(getMinimumDate()).add(1, "y").format(lang.formatDate)}
          disabled={ formData.period === "ANUAL" || formData.period === "MENSUAL" }
          error={hasError("dateTo")}
          errorMessage={getError("dateTo")}
        />
        </div>
        <div className="col">
        <Select
          id="ap-cantidad"
          name="quantity"
          options={getQuantityValues()}
          label={lang.form.quantity}
          onChange={handleChange}
          value={formData.quantity}
          error={hasError("quantity")}
          errorMessage={getError("quantity")}
        />
        </div>
        <div className="col d-flex justify-content-center">
        <Button className="col-10 button-primary-solid-ap" onClick={handleSubmit}>
          {lang.form.accept}
        </Button>
        </div>
    </div>
  );
};

export default InfoHeaderForm;
