import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInsuredService } from '../../Services';

const createBaseAsyncMethod = (actionName: string, serviceMethod: any) => {
  return createAsyncThunk(
    actionName,
    async (_,{ rejectWithValue }: any) => {
      const response = await serviceMethod();
        if(response?.data?.length && response?.status === 200){
          return response.data;
        }
        else {
          return rejectWithValue({ errorMessage: 'Error al obtener las actividades'})
        }
      
    }
  );
}

export const getInsuredServiceAsync = createBaseAsyncMethod('insured/fetchInsured',  getInsuredService()?.getInsured);
