import API from "./api";
import { getBeneficiaryPercentage, getBeneficiaryType, getCoverages, getTaxConditionValue } from "../Pages/Plans/utils";
import moment from "moment";
import { stringify } from "querystring";
import axios from "axios";
import { obligatedSubjectFormData } from '../Pages/Data/utils';

export class QuoteService {

    public quote = async (data: any) => {
        const payload = { 
            "tipoPlan": data.formData.commercialPlan,
            "nomina": {
                "actividad": data.formData.activity,
                "capitas": data.formData.quantity 
            },
            "vigencia": {
                "tipoVigencia": data.formData.period,
                "fechaInicio": data.formData.dateFrom,
                "fechaFin": data.formData.dateTo
            },
            "contacto": {
                "nombre": data.formData.firstName,
                "apellido": data.formData.lastName,
                "telefono": {
                    "prefijo": data.formData.prefix,
                    "numero": data.formData.phone
                },
                "email": data.formData.email
            }
        }
        const res = API.post('/ventas/', payload, {
            headers: {
                "g-recaptcha-response": data.formData.captcha
            }
        });
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err
        })
    };

    public getQuote = async (data: any) => {
        const res = API.get(`/ventas/${data}`);
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err
        })
    };

    public updateQuote = async (data: any) => {

        
        const payload = { 
            "tipoPlan": data.formData.commercialPlan,
            "nomina": {
                "actividad": data.formData.activity,
                "capitas": data.formData.quantity 
            },
            "vigencia": {
                "tipoVigencia": data.formData.period,
                "fechaInicio": data.formData.dateFrom,
                "fechaFin": data.formData.dateTo
            }
        }
        const res = API.patch(`/ventas/${data.quoteHash}`, payload);
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err
        })
    };

    public selectedPlan = async (data: any) => {
        const payload = {
            'planSeleccionado': data.selectedPlan
        };

        const res = API.patch(`/ventas/${data.quoteHash}`, payload);
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err
        })
    };

    public getBeneficiaryTypes = async (data: any) => {
        const res = API.post('negocio/tipos-beneficiario/opciones', {});
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err
        })
    };


    public emmitQuote = async (data: any) =>{
        const {personalData, paymentData} = data;
        const {
            personalFormData,
            politicallyExposedFormData,
            insuredsFormData,
            beneficiaryFormData,
            obligatedSubjectFormData
        } = personalData
        
        const getBenefitiaryPayloadData = (insuredIndex: number) => {
            switch (beneficiaryFormData.beneficiaryType){
                case("TOMADOR"):
                    return([{"tipoBeneficiario": "TOMADOR"}])
                case("FISICO"):
                    return beneficiaryFormData.beneficiaryInsures[insuredIndex]?.map((beneficiary: any) => ({
                        "nombre": beneficiary.name || null,
                        "apellido": beneficiary.lastname || null,
                        "dni": beneficiary.dni || null,
                        "porcentaje": beneficiary.benefitPercentage,
                        "tipoBeneficiario": "FISICO",
                        "relacion": beneficiary.beneficiaryRelationship,
                    }))
                case("JURIDICO"):
                    return([{
                        "tipoBeneficiario": "JURIDICO",
                        "razonSocial": beneficiaryFormData.companyData.businessName || null,
                        "cuit": beneficiaryFormData.companyData.cuit || null,
                        "porcentaje":"100"
                    }])
                default:
                    return [];
            }
        }

        const [tipoDocumento, numeroDocumento] = personalFormData.condicionImpositiva !== "CONSUMIDOR_FINAL"
            ?
                ["CUIT", personalFormData.cuit]
            :
                ["DNI", personalFormData.dni];

        const [sexo, nombre, apellido, razonSocial] = personalFormData.tipoPersona !== "FISICA"
            ? 
                ["", "", "", personalFormData.socialReason]
            :
                [personalFormData.sex, personalFormData.name, personalFormData.lastname, ""];

        const payload = {
            "cliente": {
                "tipoPersona": personalFormData.tipoPersona || null,
                "condicionImpositiva": personalFormData.condicionImpositiva || null,
                "documento": {
                    "numero": numeroDocumento || null,
                    "tipo": tipoDocumento || null
                },
                "sexo": sexo || null,
                "nombre": nombre || null,
                "apellido": apellido || null,
                "razonSocial": razonSocial || null,
                "fechaNacimiento": personalFormData.birthday || null,
                "nacionalidad": personalFormData.nationality || null,
                "actividad": personalFormData.takerActivity || null,
                "email": personalFormData.email || null,
                "telefono": {
                    "prefijo": personalFormData.areaCode || null,
                    "numero": personalFormData.phone || null
                },
                "direccion": {
                    "calle": personalFormData.street || null,
                    "numero": personalFormData.streetNumber || null,
                    "piso": personalFormData.floor || null,
                    "departamento": personalFormData.number || null,
                    "localizacion": {
                        "id": personalFormData.localityID || null
                    }
                },
                "esPep": politicallyExposedFormData.politicallyExposed,
                "sujetoObligado": obligatedSubjectFormData.obligatedSubject,
                "motivoPep": politicallyExposedFormData.reason || null
            },
            "nomina": {
                "asegurados": insuredsFormData?.map((insured: any, index: number) => ({
                    "dni": insured.documentNumber || null,
                    "nombre": insured.name || null,
                    "apellido": insured.lastname || null,
                    "fechaNacimiento": insured.birthDate || null,
                    "beneficiarios": getBenefitiaryPayloadData(index)
                })) || []
            },
            "clausulaNoRepeticion": {
                "empresas": beneficiaryFormData?.nonRepetitionClause?.map((empresa: any) =>({
                    "cuit": empresa.cuit || null,
                    "razonSocial": empresa.businessName || null
                })) || []
            },
            "pago": paymentData.paymentMethod === "CREDITO" ?
                {
                    "tipo": "CREDITO",
                    "empresaTarjeta": paymentData.cardCompany || null,
                    "numeroTarjeta": paymentData.cardNumber || null,
                    "mesVencimiento": paymentData.expirationMonth || null,
                    "anioVencimiento": paymentData.expirationYear || null,
                    "cbu": null,
                    "banco": null
                } :
                {
                    "tipo": "DEBITO",
                    "empresaTarjeta": null,
                    "numeroTarjeta": null,
                    "mesVencimiento": null,
                    "anioVencimiento": null,
                    "cbu": paymentData.CBU || null,
                    "banco": paymentData.bankId || null
                }
        };

        try {
            const res = await API.post(`/ventas/${data.quoteHash}/emision`, payload);
            return res;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
              return error.response
            } else {
                return error
            }
        }
    }

}
