import { createSlice } from '@reduxjs/toolkit';
import { emmitQuoteAsync } from './Actions';

export interface EmmitQuoteState {
  status: 'idle' | 'loading' | 'failed';
  errorMessage: string | undefined;
  data: any;
  canGoBack: boolean;
  refreshToken: boolean;
}

const initialState: EmmitQuoteState = {
  status: 'idle',
  errorMessage: '',
  data: null,
  refreshToken: false,
  canGoBack: false,
};

export const emmitQuoteSlice = createSlice({
  name: 'EmmitQuote',
  initialState,
  reducers: {
    emitQuoteAsyncStep: (state) => {
      state.data = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(emmitQuoteAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(emmitQuoteAsync.rejected, (state, action: any) => {
        state.status = 'failed';
        state.data = null;
        state.errorMessage =
          action.payload?.errorMessage || 'Hubo un problema desconocido';
        state.refreshToken = action.payload?.refreshToken || '';
        state.canGoBack = action.payload?.canGoBack || false;
      })
      .addCase(emmitQuoteAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.errorMessage = '';
        state.data = action.payload;
      });
  },
});

export const { emitQuoteAsyncStep } = emmitQuoteSlice.actions;

export default emmitQuoteSlice.reducer;
