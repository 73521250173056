import { ActivitiesService } from './Activities-service';
import { QuoteService } from './Quote-service';
import { LocalitiesService } from './Localities-service';
import { InsuredService } from './Insured-service'
import { PlansType } from './PlansType-service';
import { BeneficiaryTypesService } from './BeneficiaryTypes';
import { CardsBrandsService } from './CardsBrands-service';
import { PaymentMethodsService } from './PaymentMethods-service';
import { NationalitiesService } from './Nationalities-service';
import { TaxConditionsService } from './TaxConditions-service';
import { TakerActivitiesService } from './TakerActivities-service';
import { PersonTypesService } from './PersonTypes';
import { GendersService } from './Gender-service';
import { PhoneValidatorService } from './PhoneValidator-service';
import { CardValidatorService } from './CardValidator-service';
import { CBUValidatorService } from './cbuValidator';
import { CUITValidatorService } from './CuitValidator';
import { BeneficiaryRelationshipService } from './BeneficiaryRelationship';
import { EnvVariablesService } from './EnvVarialbes-service';
import { ValidityTypesService } from './ValidityTypes';

const serviceLocalities = null,
    serviceBanks = null, serviceTakerActivities = null,
    serviceTaxConditions = null,
    serviceCardsBrands = null,
    servicePaymentMethods = null,
    serviceNationalities = null,
    serviceActivities = null,
    serviceBI = null,
    servicePlansType = null,
    serviceInsured = null,
    serviceQuote = null,
    servicePersonTypes = null,
    serviceGenders = null,
    servicePhoneValidator = null,
    serviceCardValidator = null,
    serviceCBUValidator = null,
    serviceCUITValidator = null,
    serviceBeneficiaryTypes = null,
    serviceValidityTypes = null;

// export const getAuthService = () => {   
//     if(serviceAuth == null) {
//         return new AuthService();
//     }
//     else {
//         return serviceAuth;
//     }
// }

export const getLocalitiesService = () => {
    if (serviceLocalities == null) {
        return new LocalitiesService();
    }
    else {
        return serviceLocalities;
    }
}

export const getActivitiesService = () => {
    if (serviceActivities == null) {
        return new ActivitiesService();
    }
    else {
        return serviceActivities;
    }
}

export const getQuoteService = () => {
    if (serviceQuote == null) {
        return new QuoteService();
    }
    else {
        return serviceQuote;
    }
}

export const getInsuredService = () => {
    if (serviceInsured == null) {
        return new InsuredService();
    }
    else {
        return serviceInsured;
    }
}

export const getPlansType = () => {
    if (servicePlansType == null) {
        return new PlansType();
    }
    else {
        return servicePlansType;
    }
}

export const getBeneficiaryTypesService = () => {
    if (serviceBeneficiaryTypes == null) {
        return new BeneficiaryTypesService();
    }
    else {
        return serviceBeneficiaryTypes;
    }
}

export const getCardsBrandsService = () => {
    if (serviceCardsBrands == null) {

        return new CardsBrandsService();
    }
    else {

        return serviceCardsBrands;
    }
}

export const getPaymentMethodsService = () => {
    if (servicePaymentMethods == null) {

        return new PaymentMethodsService();
    }
    else {

        return servicePaymentMethods;
    }
}

export const getTaxConditionsService = () => {
    if (serviceTaxConditions == null) {

        return new TaxConditionsService;
    }
    else {

        return serviceTaxConditions;
    }
}

export const getNationalitiesService = () => {
    if (serviceNationalities == null) {

        return new NationalitiesService;
    }
    else {

        return serviceNationalities;
    }
}

export const getTakerActivitiesService = () => {
    if (serviceTakerActivities == null) {

        return new TakerActivitiesService;
    }
    else {

        return serviceTakerActivities;
    }
}

export const getPersonTypesService = () => {
    if (servicePersonTypes == null) {

        return new PersonTypesService;
    }
    else {

        return servicePersonTypes;
    }
}

export const getGendersService = () => {
    if (serviceGenders == null) {

        return new GendersService;
    }
    else {

        return serviceGenders;
    }
}

export const getPhoneValidator = () => {
    if (servicePhoneValidator == null) {

        return new PhoneValidatorService;
    }
    else {

        return servicePhoneValidator;
    }
}

export const getCardValidator = () => {
    if (serviceCardValidator === null) {

        return new CardValidatorService;
    }
    else {

        return serviceCardValidator;
    }
}

export const getCBUValidator = () => {
    if (serviceCBUValidator === null) {

        return new CBUValidatorService;
    }
    else {

        return serviceCBUValidator;
    }
}
export const getCUITValidator = () => {
    if (serviceCUITValidator === null) {

        return new CUITValidatorService;
    }
    else {

        return serviceCUITValidator;
    }
}

export const getBeneficiaryRelationshipService = () => {
    return new BeneficiaryRelationshipService();
}

export const getEnvVariablesValidator = () => {
    return new EnvVariablesService();
}

export const getValidityTypesService = () => {
    if (serviceValidityTypes == null) {

        return new ValidityTypesService;
    }
    else {

        return serviceValidityTypes;
    }
}