import { createAsyncThunk } from '@reduxjs/toolkit';
import { getValidityTypesService } from '../../Services';

const createBaseAsyncMethod = (actionName: string, serviceMethod: any) => {
  return createAsyncThunk(
    actionName,
    async (_, { rejectWithValue }: any) => {
      const response = await serviceMethod();
      /* manipular response y checkeo de errores */
      
        if(response?.data && response?.status === 200){
          return response.data.map((condition: any) => {
            return { name: condition.label, value: condition.value, description: condition.description };
        })
        } else {
          return rejectWithValue({ errorMessage: 'Error al obtener los tipos de vigencia'})
        }
      
    }
  );
}

export const getValidityTypesAsync = createBaseAsyncMethod('validityTypes/fetchGetValidityTypes',  getValidityTypesService()?.getValidityTypes);


